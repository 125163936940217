<template>
  <div :class="['mainBGWelcomeBack', isOpenModal ? 'open' : '']">
    <div>
      <div class="btn-cancel d-flex justify-end">
        <div class="border-btn-cancel d-flex justify-center align-center">
          <a id="WelcomeBack_button_cancel" @click="onCancel()">
            <img src="@/assets/images/selfonboard/close-back.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="mainModal">
        <div class="title">
          <div class="d-flex justify-center">
            <div class="icon danger d-flex justify-center align-center">
              <img
                src="@/assets/images/selfonboard/chat-smile.svg"
                alt=""
                width="60"
                height="60"
              />
            </div>
          </div>
          <div class="titleText mt-3" :style="{ 'font-size': fontSize + 'px' }">
            {{ title }}
          </div>
        </div>
        <div class="details text-center" v-html="details"></div>
        <div class="text-center mt-3 mb-0 div-contact">
          {{ this.lbl['selfonboard-buzzebees-name'] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    title: {
      type: String,
    },
    details: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
    },
    onCancel: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: '24',
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.mainBGWelcomeBack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black-b3;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 530px;
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    padding: 24px;
    z-index: 9999;
    text-align: left;
    .title {
      text-align: center;
      .icon {
        width: 90px;
        height: 90px;
        padding: 10px;
        border-radius: 4px;
        background-color: $color-red-f3;
        &.danger {
          background-color: $color-red-e0;
        }
        &.question {
          background-color: $color-blue-e6;
        }
      }
      .titleText {
        margin-left: 8px;
      }
    }
    .details {
      margin-top: 10px;
      font-size: 14px;
      .color-red-day {
        color: $red;
      }
    }
    .btnContainer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
    }
  }
  .div-contact {
    font-weight: 500;
    color: $color-link;
  }
  .btn-cancel {
    margin-right: -40px;
    .border-btn-cancel {
      background-color: $color-white;
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
    @media (max-width: 624px) {
      margin-right: 0;
      margin-bottom: -40px;
    }
  }
}
</style>
