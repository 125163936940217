<template>
  <div class="InsightsHistory">
    <b-modal
      id="modal-history"
      ref="my-modal-history"
      :modalClass="[currentLocale]"
      hide-footer
    >
      <template #modal-header="{ close }">
        <span class="svg-icon svg-icon-primary svg-icon-2x"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <path
                d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
                fill="#3699FF"
                opacity="0.3"
              ></path>
              <path
                d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                fill="#3699FF"
              ></path>
            </g></svg
        ></span>
        <h5>History</h5>
        <b-button size="lg" class="b-modal-header" @click="close()">
          <a-icon type="close" />
        </b-button>
      </template>
      <div class="d-block text-center modal-InsightsHistory">
        <a-tabs>
          <a-tab-pane key="1">
            <template #tab>
              <span>
                <apple-outlined />
                Earn
              </span>
            </template>
            <a-table
              row-key="RowKey"
              :columns="columnsInsightEarn"
              :data-source="dataInsightEarn"
              :pagination="{
                pageSize: pageSize,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                pageSizeOptions: pageSizeOptions,
                defaultPageSize: defaultPageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageEarn,
              }"
              class="pt-3"
            >
              <template slot="sex" slot-scope="text, record">
                <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                <span v-else-if="record.Gender == 'female'">{{
                  lbl['Female']
                }}</span>
                <span v-else>{{ lbl['Other'] }}</span>
              </template>
              <template slot="date" slot-scope="text">
                {{ text ? dateShortFormatUTC(text) : '-' }}
              </template>
              <template slot="isdata" slot-scope="text">
                {{ text ? text : '-' }}
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template #tab>
              <span>
                <android-outlined />
                Redeemed
              </span>
            </template>
            <a-table
              row-key="RowKey"
              :columns="columnsInsightRedeemed"
              :data-source="dataInsightRedeemed"
              :pagination="{
                pageSize: pageSize,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                pageSizeOptions: pageSizeOptions,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageRedeemed,
              }"
              class="pt-3"
            >
              <template slot="sex" slot-scope="text, record">
                <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                <span v-else-if="record.Gender == 'female'">{{
                  lbl['Female']
                }}</span>
                <span v-else>{{ lbl['Other'] }}</span>
              </template>
              <template slot="date" slot-scope="text">
                {{ text ? dateShortFormatUTC(text) : '-' }}
              </template>
              <template slot="isdata" slot-scope="text">
                {{ text ? text : '-' }}
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import Locale from '@/helper/locale.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'

const columnsInsightEarn = [
  {
    title: 'Campaigns',
    dataIndex: 'name',
    key: 'dashboard-insight-campaigns',
    scopedSlots: {
      filterDropdown: 'filterDropdownEarn',
      filterIcon: 'filterIconEarn',
      customRender: 'isdata',
    },
  },
  {
    title: 'On',
    dataIndex: 'on',
    key: 'dashboard-insight-on',
    scopedSlots: {
      filterDropdown: 'filterDropdownEarn',
      filterIcon: 'filterIconEarn',
      customRender: 'isdata',
    },
  },
  {
    title: 'Earned (point$)',
    dataIndex: 'point',
    key: 'dashboard-insight-point',
    scopedSlots: {
      filterDropdown: 'filterDropdownEarn',
      filterIcon: 'filterIconEarn',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.point
        ? record.point.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputEarn.focus()
        }, 0)
      }
    },
  },
]

const columnsInsightRedeemed = [
  {
    title: 'Rewards',
    dataIndex: 'name',
    key: 'dashboard-insight-rewards',
    scopedSlots: {
      filterDropdown: 'filterDropdownRedeemed',
      filterIcon: 'filterIconRedeemed',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.name
        ? record.name.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputRedeemed.focus()
        }, 0)
      }
    },
  },
  {
    title: 'On',
    dataIndex: 'on',
    key: 'dashboard-insight-on',
    scopedSlots: {
      filterDropdown: 'filterDropdownRedeemed',
      filterIcon: 'filterIconRedeemed',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.on
        ? record.on.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputRedeemed.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Used (point$)',
    dataIndex: 'point',
    key: 'dashboard-insight-used',
    scopedSlots: {
      filterDropdown: 'filterDropdownRedeemed',
      filterIcon: 'filterIconRedeemed',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.point
        ? record.point.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputRedeemed.focus()
        }, 0)
      }
    },
  },
]

export default {
  mixins: [Mixin],
  props: {
    data: Object,
    show: Boolean,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      pageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      //InsightEarn
      columnsInsightEarn,
      dataInsightEarn: [],
      totalInsightEarn: null,
      currentInsightEarn: 0,
      searchInputEarn: null,
      searchTextEarn: '',

      //InsightRedeemed
      columnsInsightRedeemed,
      dataInsightRedeemed: [],
      totalInsightRedeemed: null,
      currentInsightRedeemed: 0,
      searchInputRedeemed: null,
      searchTextRedeemed: '',
      searchedColumnRedeemed: '',
      appId: null,
    }
  },
  watch: {
    show(newVal, oldVal) {
      console.log('Prop show: ', newVal, ' | was: ', oldVal)
      if (newVal == true) {
        this.getCRMPlusProfile().then(result => {
          this.appId = result.AppId
          this.getListInsightsEarn(0)
          this.getListInsightsRedeemed(0)
          this.$refs['my-modal-history'].show()
        })
      }
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'modal-history') {
        this.$emit('changeInsightsHistory', false)
      }
    })
  },
  created() {
    this.setLocale()
  },
  methods: {
    setLocale() {
      _.forEach(this.columnsInsight, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    //Insight Earn
    getListInsightsEarn() {
      this.dataInsightEarn = null
      this.totalInsightEarn = 0
      this.currentInsightEarn = 0
      this.handleLoading(true)
      let params = {
        user_id: this.data.userId,
        point_option: 'all',
        startdate: this.data.startdate,
        enddate: this.data.enddate,
        appId: this.appId,
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetUserHistory(params)
          .then(res => {
            this.handleLoading(false)

            if (res.data.earned.length > 0) {
              if (this.currentInsightEarn == 0) {
                this.currentInsightEarn = 1
              }
            } else {
              this.currentInsightEarn = 0
            }

            this.totalInsightEarn = res.data.earned.length
            this.dataInsightEarn = res.data.earned.sort((a, b) => {
              return Date.parse(b.on) - Date.parse(a.on)
            })
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetUserHistory error', error)
            resolve(error)
          })
      })
    },
    onChangePageEarn(page) {
      this.currentInsightEarn = page
    },
    itemRenderEarn(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleTableSearchEarn(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchTextEarn = selectedKeys[0]
      this.searchedColumnEarn = dataIndex
    },
    handleTableResetEarn(clearFilters) {
      clearFilters()
      this.searchTextEarn = ''
    },

    //Insight Redeemed
    getListInsightsRedeemed() {
      this.dataInsightRedeemed = null
      this.totalInsightRedeemed = 0
      this.currentInsightRedeemed = 0
      this.handleLoading(true)
      let params = {
        user_id: this.data.userId,
        point_option: 'all',
        startdate: this.data.startdate,
        enddate: this.data.enddate,
        appId: this.appId,
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetUserHistory(params)
          .then(res => {
            this.handleLoading(false)

            if (res.data.redeemed.length > 0) {
              if (this.currentInsightRedeemed == 0) {
                this.currentInsightRedeemed = 1
              }
            } else {
              this.currentInsightRedeemed = 0
            }

            this.totalInsightRedeemed = res.data.redeemed.length
            this.dataInsightRedeemed = res.data.redeemed
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetUserHistory error', error)
            resolve(error)
          })
      })
    },
    onChangePageRedeemed(page) {
      this.currentInsightRedeemed = page
    },
    itemRenderRedeemed(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleTableSearchRedeemed(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchTextRedeemed = selectedKeys[0]
      this.searchedColumnRedeemed = dataIndex
    },
    handleTableResetRedeemed(clearFilters) {
      clearFilters()
      this.searchTextRedeemed = ''
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/common.scss';

#modal-history {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px 0;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }

  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .modal-header {
    border-bottom: 1px solid #dee2e6;
  }
}
</style>
