<template>
  <div :class="['mainBG', visible ? 'open' : '']">
    <div class="mainModal">
      <div class="title">
        <div class="icon warning">
          <img
            src="@/assets/images/Warning-1-circle.svg"
            alt="Warning Icon"
            width="24"
            height="24"
          />
        </div>
        <div class="titleText">
          {{ title }}
        </div>
      </div>
      <div class="btnContainer">
        <button
          id="cancel_upgrade_button"
          @click="handleCancel()"
          class="outlineprimary warning"
        >
          {{ lbl['cancel-label'] }}
        </button>
        <button
          id="goto_upgrade_button"
          @click="handleOk()"
          class="primarybtn warning"
        >
          <span> {{ lbl['click-here-label'] }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'alertUpgradePackage',
  mixins: [Mixin],

  props: {
    visible: Boolean,
    title: String,
    internal: Boolean,
    pageName: String,
  },
  methods: {
    handleOk() {
      if (this.internal) {
        this.$emit('close')
        this.$router.push({ name: this.pageName })
      } else {
        this.handleCancel()
      }
    },
    handleCancel() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.mainBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 530px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    z-index: 9999;
    text-align: left;
    .title {
      display: flex;
      .icon {
        width: 44px;
        height: 44px;
        padding: 10px;
        border-radius: 4px;
        background-color: #fff3e0;
        &.danger {
          background-color: #ffe0e0;
        }
        &.question {
          background-color: #e6f7ff;
        }
      }
      .titleText {
        font-size: 24px;
        margin-left: 8px;
      }
    }
    .banner {
      text-align: center;
      margin: 16px auto;

      img {
        width: 100px;
        height: 100px;
      }
    }
    .details {
      margin-top: 10px;
      font-size: 14px;
      .color-red-day {
        color: red;
      }
    }
    .btnContainer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
    }
  }
}
</style>
