<template>
  <div class="accept-cookie">
    <vue-cookie-accept-decline
      id="accept-cookie"
      ref="myCookieCRMPlusBackoffice"
      element-id="myCookieCRMPlusBackoffice"
      position="bottom-left"
      :debug="false"
      :disable-decline="true"
      transition-name="slideFromBottom"
      :show-postpone-button="true"
      type="floating"
      @clicked-decline="cookieClickedDecline"
      @clicked-postpone="cookieClickedPostpone"
      @removed-cookie="cookieRemovedCookie"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      :class="currentLocale == 'th' ? 'th' : 'en'"
    >
      <!-- Optional -->
      <template #postponeContent>
        <div class="close">
          <img src="@/assets/images/close.png" alt="" />
        </div>
      </template>

      <!-- Optional -->
      <template #message>
        <div class="row">
          <div class="col-12 title-cookie">
            <img class="img-cookie" src="@/assets/images/cookie.svg" alt="" />
            {{ lbl['website-uses-cookies'] }}
          </div>
          <div class="col-12 body-overflow">
            <div class="body-cookie">
              {{ lbl['website-uses-cookies-detail'] }}
              <a
                v-if="isSeeMore == false && listCookies.length > 0"
                href=""
                @click="handleSeeMore"
                >{{ lbl['accept-cookie-btn-seemore'] }}</a
              >
              <div v-if="isSeeMore == true && listCookies.length > 0">
                <div v-for="(item, idex) in listCookies" :key="idex">
                  <div class="info-title">{{ item.title }}</div>
                  <div class="info-description">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #acceptContent>{{ lbl['accept-all'] }}</template>
      <!-- Optional -->
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import Config from '@/helper/Config.js'
import Mixin from '@/mixin/Mixin'
import CheckCookie from '@/mixin/CheckCookie'
import Locale from '@/helper/locale.js'

export default {
  mixins: [Mixin, CheckCookie],
  data() {
    return {
      isSeeMore: false,
      listCookies: [],
      currentLocale: Locale.getLocaleShort(),
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getCookieInfo()
    },
    cookieClickedDecline() {
      console.log('cookieClickedDecline')
    },
    cookieClickedPostpone() {
      console.log('cookieClickedDecline')
    },
    cookieRemovedCookie() {
      console.log('cookieClickedDecline')
    },
    cookieStatus() {
      console.log('cookieStatus')
    },
    cookieClickedAccept() {
      console.log('cookieClickedAccept')
    },
    getCookieInfo() {
      Config.getCookies().then(res => {
        if (res.data.cookies.length > 0) {
          this.listCookies = res.data.cookies
        } else {
          this.listCookies = []
        }
      })
    },
    handleSeeMore(e) {
      e.preventDefault()
      this.isSeeMore = true
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
#accept-cookie {
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1 !important;
    color: #000;
    img {
      cursor: pointer;
    }
  }

  .cookie {
    border: solid 1px #e7e7e7;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  }

  .cookie__floating__buttons__button:first-child {
    border: initial;
    background-color: #f9a601;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    margin: 10px 15px !important;
  }

  .cookie__floating--bottom-left {
    bottom: 20px;
    left: auto;
    right: 0;
    text-align: center;
  }

  .img-cookie {
    margin-top: -3px;
  }

  .title-cookie {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #424242;
    position: absolute;
    top: 11px;
  }

  .info-title {
    font-weight: 600;
    color: #686868;
    margin-top: 10px;
  }

  .info-description {
    margin-bottom: 2px;
  }

  .body-overflow {
    overflow: auto;
    max-height: 70vh;
  }

  .body-cookie {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #757575;
    margin-top: 10px;
  }

  .cookie__floating__content {
    font-size: 0.95rem;
    margin-bottom: 0;
    padding: 15px 20px 10px;
    max-height: 80vh !important;
    overflow: initial !important;
  }

  .cookie__floating {
    max-width: 500px !important;
    width: auto !important;
  }

  @media only screen and (max-device-width: 768px) {
    .cookie__floating {
      min-width: 90% !important;
      width: 90% !important;
    }

    .cookie__floating--bottom-left {
      bottom: 20px;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
    }

    .cookie__floating__content {
      font-size: 0.95rem;
      margin-bottom: 0;
      padding: 15px 20px 10px;
      max-height: initial;
      overflow: auto;
    }
  }
}

.cookie__floating {
  max-width: 500px !important;
  width: auto !important;
}

.cookie__floating__content {
  font-size: 0.95rem;
  margin-bottom: 0;
  padding: 15px 20px 10px;
  max-height: initial;
  overflow: auto;
}

.cookie__floating--bottom-left {
  bottom: 20px;
  left: auto !important;
  right: 20px !important;
  margin: 0 auto !important;
}

@media only screen and (max-device-width: 768px) {
  .cookie__floating {
    min-width: 90% !important;
    width: 90% !important;
  }

  .cookie__floating--bottom-left {
    bottom: 20px;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }

  .cookie__floating__content {
    font-size: 0.95rem;
    margin-bottom: 0;
    padding: 15px 20px 10px;
    max-height: initial;
    overflow: auto;
  }
}
</style>
