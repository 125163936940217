export default function DashboardModel(dict) {
    this.subCampaignDetails = [];
    if (dict) {
        this.cat = dict.cat;
        this.cat_header_en = dict.cat_header_en;
        this.cat_header_th = dict.cat_header_th;
        this.menu = dict.menu;
        this.type = dict.type;
        this.size = dict.size;
        this.imgType = dict.imgtype;
        this.id = dict.id;
        this.icon_url = dict.icon_url;
        this.imageUrl = dict.image_url;
        this.ios_schema = dict.ios_schema;
        this.line1 = dict.line1;
        this.line2 = dict.line2;
        this.line3 = dict.line3;
        this.name = dict.name;
        this.url = dict.url;
        this.strGA = dict.ga_label;
        this.subCat = dict.subcat;

        if (dict.subcampaigndetails && dict.subcampaigndetails instanceof Array) {
            for( var i=0; i<dict.subcampaigndetails.length; i++) {
                this.subCampaignDetails.push(new DashboardModel(dict.subcampaigndetails[i]));
            }
        }
    }
}
