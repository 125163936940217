import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import DashboardModel from '../model/DashboardModel.js';
import Cache from '@/helper/Cache.js';

/**
 * @method apiGetDashboard
 * @method apiGetSubDashboard
 * @method apiGetTotalUser
 * @method apiGetTotalCompanyUser
 * @method apiGetDailyUser
 * @method apiGetRedemption
 * @method apiGetEarnStamp
 * @method apiGetMonthlyActive
 * @method apiGetCampaign
 * @method apiGetBranch
 * @method apiGetNewUser
 * @method apiGetChartEarnStamp
 * @method apiGetBurnUser
 * @method apiGetActiveGender
 * @method apiGetActiveAge
 * @method apiGetActiveFrequency
 * @method apiGetUserInfo
 * @method apiGetUserHistory
 * @method apiGetUIBranch
 * @method apiGetUICampaign
 * @method apiGetWalletReport
 * @method apiGetRedeemTransaction
 */
export default {
	config: function() {
		return Vue.bzbsConfig;
	},
	token: function() {
		return Cache.get('CRMUser').Token;
	},
	app_id: function() {
		return Cache.get('CRMUser').AppId;
	},
	agency_id: function() {
		return Cache.get('CRMUser').AgencyId;
	},
	token_stamp: function() {
		var tokenStamp = Cache.get('TokenStampMerc');
		if (tokenStamp != null) {
			return Cache.get('TokenStampMerc');
		} else {
			return null;
		}
	},

	/**
   * Get main dashboard
   * @param {object} params DashboardConfigModel - /core/Campaign/model/DashboardConfigModel.js
   * @return {Promise} list of DashboardModel
   */
	apiGetDashboard: function(params) {
		var strUrl = this.config().bzbsUrl + '/main/dashboard';
		return new Promise((resolve, reject) => {
			BzbsApi.connectBzbsGet(strUrl, null, params)
				.then((resp) => {
					var data = resp.data;
					if (data instanceof Array) {
						var listDashboard = [];
						for (var i of data) {
							listDashboard.push(new DashboardModel(i));
						}
						resolve(listDashboard);
					} else {
						reject(null);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/**
   * Get custom dashboard
   * @param {string} strBzbsToken (optional)
   * @param {object} params (require) SubDashboardConfigModel  - /service/config/DashboardConfigModel.js
   * @return {Pormoise} list of DashboardModel
   */
	apiGetSubDashboard: function(strBzbsToken, params, dashboardName) {
		var strUrl = this.config().bzbsUrl + '/dashboard/' + dashboardName;
		return new Promise((resolve, reject) => {
			BzbsApi.connectBzbsGet(strUrl, strBzbsToken, params)
				.then((resp) => {
					var data = resp.data;
					if (data instanceof Array) {
						var listDashboard = [];
						for (var i of data) {
							listDashboard.push(new DashboardModel(i));
						}
						resolve(listDashboard);
					} else {
						reject(null);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	apiGetTotalUser: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/totaluser?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},

	apiGetTotalCompanyUser: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/totalcompanyuser?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},

	apiGetDailyUser: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/dailyuser?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},

	apiGetRedemption: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/totalRedemption?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},

	apiGetEarnStamp: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusdatasci/totalearnstamp?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},

	apiGetMonthlyActive: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/daily30user?agencyId=' +
			this.agency_id() +
			'&month=' +
			params.month +
			'&year=' +
			params.year;

		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetCampaign: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/top5campaign?appId=' +
			this.app_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate +
			'&agencyId=' +
			this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetBranch: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/maindashBranch?agencyId=' +
			this.agency_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetNewUser: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/newuser?agencyId=' +
			this.agency_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetChartEarnStamp: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/earnstamp?agencyId=' +
			this.agency_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetBurnUser: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/burnuser?agencyId=' +
			this.agency_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetActiveGender: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/activegender?appId=' +
			this.app_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate +
			'&agencyId=' +
			this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetActiveAge: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/activeage?appId=' +
			this.app_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate +
			'&agencyId=' +
			this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetActiveFrequency: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/activefeq?appId=' +
			this.app_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate +
			'&agencyId=' +
			this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetUserInfo: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/insightsUserInfo?agencyId=' +
			this.agency_id() +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetUserHistory: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/userhistory?agencyId=' +
			this.agency_id() +
			'&user_id=' +
			params.user_id +
			'&startDate=' +
			params.startdate +
			'&endDate=' +
			params.enddate +
			'&point_option=' +
			params.point_option +
			'&appId=' + params.appId;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},

	apiGetUIBranch: function(params) {
		var strUrl = this.config().dataReportUrl + '/branch/?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, null, params);
	},
	apiGetWalletReportBuzzportal: function(params) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusdatasci/walletreport?appId=' +
			this.app_id() +
			'&agencyId=' +
			this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	apiGetUICampaign: function(params) {
		var strUrl = this.config().bzbsApiWallet + '/report/getcampaign?token=' + this.token_stamp();
		return BzbsApi.connectBzbsGet(strUrl, null, params);
	},
	apiGetWalletReport: function(params) {
		var strUrl =
			this.config().bzbsApiWallet + '/report/walletreport?token=' + this.token_stamp() + '&offset=0' + '&limit=0';
		return BzbsApi.connectBzbsGet(strUrl, null, params);
	},
	apiGetRedeemTransaction: function(params) {
		var strUrl =
			this.config().bzbsApiWallet +
			'/report/redeemtransaction?token=' +
			this.token_stamp() +
			'&offset=0' +
			'&limit=0';
		return BzbsApi.connectBzbsGet(strUrl, null, params);
	}
};
