var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InsightsHistory"},[_c('b-modal',{ref:"my-modal-history",attrs:{"id":"modal-history","modalClass":[_vm.currentLocale],"hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('span',{staticClass:"svg-icon svg-icon-primary svg-icon-2x"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24px","height":"24px","viewBox":"0 0 24 24","version":"1.1"}},[_c('g',{attrs:{"stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"24","height":"24"}}),_c('path',{attrs:{"d":"M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z","fill":"#3699FF","opacity":"0.3"}}),_c('path',{attrs:{"d":"M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z","fill":"#3699FF"}})])])]),_c('h5',[_vm._v("History")]),_c('b-button',{staticClass:"b-modal-header",attrs:{"size":"lg"},on:{"click":function($event){return close()}}},[_c('a-icon',{attrs:{"type":"close"}})],1)]}}])},[_c('div',{staticClass:"d-block text-center modal-InsightsHistory"},[_c('a-tabs',[_c('a-tab-pane',{key:"1",scopedSlots:_vm._u([{key:"tab",fn:function(){return [_c('span',[_c('apple-outlined'),_vm._v(" Earn ")],1)]},proxy:true}])},[_c('a-table',{staticClass:"pt-3",attrs:{"row-key":"RowKey","columns":_vm.columnsInsightEarn,"data-source":_vm.dataInsightEarn,"pagination":{
              pageSize: _vm.pageSize,
              showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
              pageSizeOptions: _vm.pageSizeOptions,
              defaultPageSize: _vm.defaultPageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
              onChange: _vm.onChangePageEarn,
            }},scopedSlots:_vm._u([{key:"sex",fn:function(text, record){return [(record.Gender == 'male')?_c('span',[_vm._v(_vm._s(_vm.lbl['Male']))]):(record.Gender == 'female')?_c('span',[_vm._v(_vm._s(_vm.lbl['Female']))]):_c('span',[_vm._v(_vm._s(_vm.lbl['Other']))])]}},{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.dateShortFormatUTC(text) : '-')+" ")]}},{key:"isdata",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : '-')+" ")]}}])})],1),_c('a-tab-pane',{key:"2",scopedSlots:_vm._u([{key:"tab",fn:function(){return [_c('span',[_c('android-outlined'),_vm._v(" Redeemed ")],1)]},proxy:true}])},[_c('a-table',{staticClass:"pt-3",attrs:{"row-key":"RowKey","columns":_vm.columnsInsightRedeemed,"data-source":_vm.dataInsightRedeemed,"pagination":{
              pageSize: _vm.pageSize,
              showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
              pageSizeOptions: _vm.pageSizeOptions,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
              onChange: _vm.onChangePageRedeemed,
            }},scopedSlots:_vm._u([{key:"sex",fn:function(text, record){return [(record.Gender == 'male')?_c('span',[_vm._v(_vm._s(_vm.lbl['Male']))]):(record.Gender == 'female')?_c('span',[_vm._v(_vm._s(_vm.lbl['Female']))]):_c('span',[_vm._v(_vm._s(_vm.lbl['Other']))])]}},{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.dateShortFormatUTC(text) : '-')+" ")]}},{key:"isdata",fn:function(text){return [_vm._v(" "+_vm._s(text ? text : '-')+" ")]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }