import Account from '@/helper/AccountHelper.js'

export default {
  beforeCreate() {
    const cookie = Account.getCacheCookieConsent()
    if (cookie == 'postpone') {
        Account.removeCacheCookieConsent()
    }
  },
}
