<template>
  <div class="TransactionsEDC">
    <a-modal
      v-model="showModalTransactions"
      :footer="null"
      class="modal-Transactions"
      :afterClose="TransactionsClose"
      :class="[currentLocale]"
      destroyOnClose
    >
      <div class="modal-header">
        <h5 class="modal-title">
          <span class="svg-icon svg-icon-primary svg-icon-2x">
            <img src="@/assets/images/dashboard/file.svg" alt="" />
          </span>
          {{ lbl['dashboard-transactions-popup-header'] }}
          <span class="d-block text-muted font-size-sm"></span>
        </h5>
      </div>
      <div class="row">
        <div class="col-12">
          <a-tabs
            :default-active-key="storeType == 'point' ? '1' : '2'"
            class="pt-2"
            destroyInactiveTabPane
            @change="
              e => {
                changeTab(e)
              }
            "
          >
            <a-tab-pane v-if="storeType == 'point'" key="1">
              <span slot="tab">
                <a-icon class="icon-color" type="book" />
                {{ lbl['dashboard-transactions-point-list-header'] }}
              </span>
              <a-form :form="formpoint">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-start-date'] }}
                      </label>
                      <a-date-picker
                        v-model="startDatePoint"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        size="large"
                        :disabled-date="disabledStartDatePoint"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-end-date'] }}
                      </label>
                      <a-date-picker
                        v-model="endDatePoint"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        size="large"
                        :disabled-date="disabledEndDatePoint"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-branch'] }}
                      </label>
                      <a-select
                        v-model="branchPoint"
                        size="large"
                        :default-value="search_point.branch[0].name"
                      >
                        <a-spin slot="notFoundContent" size="small" />

                        <a-select-option
                          v-for="(item, index) in search_point.branch"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                        {{ search_point.branch }}
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-button
                      type="primary"
                      icon="search"
                      class="button-search d-flex"
                      @click="handleSearchPoint"
                    >
                      {{ lbl['dashboard-transactions-search'] }}
                    </a-button>
                  </div>
                </div>
              </a-form>
              <div class="row pt-4 pb-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="div-import pt-3">
                    <ExportExcel
                      :text="lbl['dashboard-transactions-export-excel-button']"
                      @clickButton="exportExcelPoint()"
                    />
                  </div>
                </div>
              </div>
              <a-table
                :columns="columnsPoint"
                :data-source="dataPoint"
                :pagination="{
                  pageSize: defaultPageSize,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePagePoint,
                }"
                class="pt-2"
              >
                <div
                  slot="filterDropdownPoint"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="c => (searchInputPoint = c)"
                    :placeholder="`${lbl['dashboard-insight-search-button']} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleTableSearchPoint(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  />
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleTableSearchPoint(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  >
                    {{ lbl['dashboard-insight-search-button'] }}
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleTableResetPoint(clearFilters)"
                  >
                    {{ lbl['confirm-box-cancel-button'] }}
                  </a-button>
                </div>
                <a-icon
                  slot="filterIconPoint"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template slot="sex" slot-scope="text, record">
                  <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                  <span v-else-if="record.Gender == 'female'">{{
                    lbl['Female']
                  }}</span>
                  <span v-else>{{ lbl['Other'] }}</span>
                </template>
                <template slot="date" slot-scope="text">
                  {{ text ? dateShortFormatUTC(text) : '-' }}<br />
                  {{ text ? timeFormatUTC(text) : '-' }}
                </template>
                <template slot="isdata" slot-scope="text">
                  {{ text ? text : '-' }}
                </template>
                <template slot="status" slot-scope="text, record">
                  {{ record.success ? lbl['success'] : lbl['not_success'] }}
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane v-if="storeType == 'stamp'" key="2">
              <span slot="tab">
                <a-icon class="icon-color" type="audit" />
                {{ lbl['dashboard-transactions-stamp-list-header'] }}
              </span>
              <a-form :form="formstamp">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-start-date'] }}
                      </label>
                      <a-date-picker
                        v-model="startDateStamp"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        size="large"
                        :disabled-date="disabledStartDateStamp"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-end-date'] }}
                      </label>
                      <a-date-picker
                        v-model="endDateStamp"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        :disabled-date="disabledEndDateStamp"
                        size="large"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-branch'] }}
                      </label>
                      <a-select
                        v-model="branchStamp"
                        size="large"
                        :default-value="search_stamp.branch[0].name"
                      >
                        <a-spin slot="notFoundContent" size="small" />

                        <a-select-option
                          v-for="(item, index) in search_stamp.branch"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                        {{ search_stamp.branch }}
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-button
                      type="primary"
                      icon="search"
                      class="button-search d-flex"
                      @click="handleSearchStamp"
                    >
                      {{ lbl['dashboard-transactions-search'] }}
                    </a-button>
                  </div>
                </div>
              </a-form>
              <div class="row pt-4 pb-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="div-import pt-3">
                    <ExportExcel
                      :text="lbl['dashboard-transactions-export-excel-button']"
                      @clickButton="exportExcelStamp()"
                    />
                  </div>
                </div>
              </div>
              <a-table
                :columns="columnsStamp"
                :data-source="dataStamp"
                :pagination="{
                  pageSize: defaultPageSize,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePageStamp,
                }"
                class="pt-2"
              >
                <div
                  slot="filterDropdownStamp"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="c => (searchInputStamp = c)"
                    :placeholder="`${lbl['dashboard-insight-search-button']} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleTableSearchStamp(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  />
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleTableSearchStamp(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  >
                    {{ lbl['dashboard-insight-search-button'] }}
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleTableResetStamp(clearFilters)"
                  >
                    {{ lbl['confirm-box-cancel-button'] }}
                  </a-button>
                </div>
                <a-icon
                  slot="filterIconStamp"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template slot="sex" slot-scope="text, record">
                  <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                  <span v-else-if="record.Gender == 'female'">{{
                    lbl['Female']
                  }}</span>
                  <span v-else>{{ lbl['Other'] }}</span>
                </template>
                <template slot="date" slot-scope="text">
                  {{ text ? dateShortFormatUTC(text) : '-' }}
                </template>
                <template slot="isdata" slot-scope="text">
                  {{ text ? text : '-' }}
                </template>
                <template slot="status" slot-scope="text, record">
                  {{ record.success ? lbl['success'] : lbl['not_success'] }}
                </template>
                <template slot="status_success" slot-scope="text">
                  {{
                    text == 3
                      ? lbl['complete-label']
                      : text == 2
                      ? lbl['refund-label']
                      : text == 4
                      ? lbl['cancel-label']
                      : '-'
                  }}
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="3">
              <span slot="tab">
                <a-icon class="icon-color" type="star" theme="filled" />
                {{ lbl['dashboard-transactions-permissions-list-header'] }}
              </span>
              <a-form :form="formpermissions">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item mb-2">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-start-date'] }}
                      </label>
                      <a-date-picker
                        v-model="startDatePermissions"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        size="large"
                        :disabled-date="disabledStartDatePermissions"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item mb-2">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-end-date'] }}
                      </label>
                      <a-date-picker
                        v-model="endDatePermissions"
                        :default-value="defaultDate"
                        :allowClear="false"
                        format="DD/MM/YYYY"
                        size="large"
                        :disabled-date="disabledEndDatePermissions"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item mb-2">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-campaign'] }}
                      </label>
                      <a-select
                        v-model="campaignPermissions"
                        size="large"
                        :default-value="search_permissions.campaign[0].id"
                        :dropdownStyle="{ minWidth: 'fit-content' }"
                      >
                        <a-spin slot="notFoundContent" size="small" />

                        <a-select-option
                          v-for="(item, index) in search_permissions.campaign"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.name }}
                          <span v-if="item.id">({{ item.id }})</span>
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-form-item class="field-item mb-2">
                      <label class="text-left w-100">
                        {{ lbl['dashboard-transactions-branch'] }}
                      </label>
                      <a-select
                        v-model="branchPermissions"
                        size="large"
                        :default-value="search_permissions.branch[0].name"
                      >
                        <a-spin slot="notFoundContent" size="small" />

                        <a-select-option
                          v-for="(item, index) in search_permissions.branch"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-1 text-left lbl-error">
                    *{{
                      lbl['dashboard-redemption-transactions-message-month']
                    }}
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <a-button
                      type="primary"
                      icon="search"
                      class="button-search d-flex mt-1 mb-2"
                      @click="handleSearchPermissions"
                    >
                      {{ lbl['dashboard-transactions-search'] }}
                    </a-button>
                  </div>
                </div>
              </a-form>
              <div class="row pt-4 pb-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="div-import pt-3">
                    <ExportExcel
                      :text="lbl['dashboard-transactions-export-excel-button']"
                      @clickButton="exportExcelPermissions()"
                    />
                  </div>
                </div>
              </div>
              <a-table
                :columns="columnsPermissions"
                :data-source="dataPermissions"
                :pagination="{
                  pageSize: defaultPageSize,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePagePermissions,
                }"
                class="pt-2"
              >
                <div
                  slot="filterDropdownPermissions"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="c => (searchInputPermissions = c)"
                    :placeholder="`${lbl['dashboard-insight-search-button']} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleTableSearchPermissions(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  />
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleTableSearchPermissions(
                          selectedKeys,
                          confirm,
                          column.dataIndex,
                        )
                    "
                  >
                    {{ lbl['dashboard-insight-search-button'] }}
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleTableResetPermissions(clearFilters)"
                  >
                    {{ lbl['confirm-box-cancel-button'] }}
                  </a-button>
                </div>
                <a-icon
                  slot="filterIconPermissions"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template slot="sex" slot-scope="text, record">
                  <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                  <span v-else-if="record.Gender == 'female'">{{
                    lbl['Female']
                  }}</span>
                  <span v-else>{{ lbl['Other'] }}</span>
                </template>
                <template slot="date" slot-scope="text">
                  {{ text ? dateShortFormatUTC(text) : '-' }}
                </template>
                <template slot="isdata" slot-scope="text">
                  {{ text ? text : '-' }}
                </template>
                <template slot="isdata_fullname" slot-scope="text, record">
                  <span v-if="record.FirstName && record.LastName">
                    {{ record.FirstName }} {{ record.LastName }}
                  </span>
                  <span v-else-if="record.FirstName && !record.LastName">
                    {{ record.FirstName }}
                  </span>
                  <span v-else-if="!record.FirstName && record.LastName">
                    {{ record.LastName }}
                  </span>
                  <span v-else> - </span>
                </template>
                <template slot="status" slot-scope="text, record">
                  {{ record.success ? lbl['success'] : lbl['not_success'] }}
                </template>
                <template slot="status_success" slot-scope="text">
                  {{
                    text == 3
                      ? lbl['complete-label']
                      : text == 2
                      ? lbl['refund-label']
                      : text == 4
                      ? lbl['cancel-label']
                      : '-'
                  }}
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import moment from 'moment'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import XLSX from 'xlsx'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import ExportExcel from '@/module/components/dashboard/botton/ExportExcel'
import Locale from '@/helper/locale.js'

const columnsPoint = [
  {
    title: 'UserID',
    dataIndex: 'UserID',
    key: 'dashboard-transactions-user-id',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.UserID
        ? record.UserID.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Line User Id',
    dataIndex: 'LineId',
    key: 'dashboard-insight-line-user-id-header',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.LineId
        ? record.LineId.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'user-permissions-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Name
        ? record.Name.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'ContactNumber',
    dataIndex: 'ContactNumber',
    key: 'company-setup-branch-contact-number-placeholder',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.ContactNumber
        ? record.ContactNumber.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'TransactionID',
    key: 'dashboard-transactions-list',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.TransactionID
        ? record.TransactionID.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'dashboard-transactions-date-time',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'date',
    },
    onFilter: (value, record) =>
      record.Date
        ? record.Date.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Branch',
    dataIndex: 'Branch',
    key: 'dashboard-transactions-branch-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Branch
        ? record.Branch.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'dashboard-transactions-quantity',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Amount
        ? record.Amount.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Give Points',
    dataIndex: 'GivePoints',
    key: 'dashboard-transactions-earn-points',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.GivePoints
        ? record.GivePoints.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Reference Code',
    dataIndex: 'ReferenceCode',
    key: 'dashboard-transactions-reference-code',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.ReferenceCode
        ? record.ReferenceCode.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Source',
    dataIndex: 'Source',
    key: 'dashboard-transactions-reference-source',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Source
        ? record.Source.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'user-permissions-status',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Status
        ? record.Status.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
]

const columnsStamp = [
  {
    title: 'UserID',
    dataIndex: 'CustomerId',
    key: 'dashboard-transactions-user-id',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.CustomerId
        ? record.CustomerId.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Line User Id',
    dataIndex: 'LineId',
    key: 'dashboard-insight-line-user-id-header',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.LineId
        ? record.LineId.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'user-permissions-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Name
        ? record.Name.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'ContactNumber',
    dataIndex: 'ContactNumber',
    key: 'company-setup-branch-contact-number-placeholder',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.ContactNumber
        ? record.ContactNumber.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'TransactionId',
    key: 'dashboard-transactions-list',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.TransactionId
        ? record.TransactionId.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Date',
    dataIndex: 'CreateDate',
    key: 'dashboard-transactions-date-time',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'date',
    },
    onFilter: (value, record) =>
      record.CreateDate
        ? record.CreateDate.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Branch',
    dataIndex: 'BranchName',
    key: 'dashboard-transactions-branch-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.BranchName
        ? record.BranchName.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'dashboard-transactions-amount',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Amount
        ? record.Amount.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Stamps',
    dataIndex: 'StampCount',
    key: 'dashboard-transactions-quantity-stamps',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.StampCount
        ? record.StampCount.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Reference Code',
    dataIndex: 'Description',
    key: 'dashboard-transactions-reference-code',
    scopedSlots: {
      filterDropdown: 'filterDropdownStamp',
      filterIcon: 'filterIconStamp',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Description
        ? record.Description.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'user-permissions-status',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'status_success',
    },
    onFilter: (value, record) =>
      record.Status
        ? record.Status.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
]

const columnsPermissions = [
  {
    title: 'UserID',
    dataIndex: 'CustomerId',
    key: 'dashboard-transactions-user-id',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.CustomerId
        ? record.CustomerId.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Line User Id',
    dataIndex: 'LineId',
    key: 'dashboard-insight-line-user-id-header',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.LineId
        ? record.LineId.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'FirstName',
    key: 'user-permissions-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata_fullname',
    },
    onFilter: (value, record) =>
      record.FirstName
        ? record.FirstName.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'ContactNumber',
    dataIndex: 'ContactNumber',
    key: 'company-setup-branch-contact-number-placeholder',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.ContactNumber
        ? record.ContactNumber.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'TransactionId',
    key: 'dashboard-transactions-list',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.TransactionId
        ? record.TransactionId.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Date',
    dataIndex: 'CreateDate',
    key: 'dashboard-transactions-date-time',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'date',
    },
    onFilter: (value, record) =>
      record.CreateDate
        ? record.CreateDate.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Branch',
    dataIndex: 'BranchName',
    key: 'dashboard-transactions-branch-name',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.BranchName
        ? record.BranchName.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Campaign',
    dataIndex: 'CampaignName',
    key: 'dashboard-transactions-campaign',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.CampaignName
        ? record.CampaignName.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Point',
    dataIndex: 'PointPerUnit',
    key: 'dashboard-transactions-point',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.PointPerUnit
        ? record.PointPerUnit.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Campaign Code',
    dataIndex: 'Serial',
    key: 'dashboard-transactions-campaign-code',
    scopedSlots: {
      filterDropdown: 'filterDropdownPermissions',
      filterIcon: 'filterIconPermissions',
      customRender: 'isdata',
    },
    onFilter: (value, record) =>
      record.Serial
        ? record.Serial.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputPermissions.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'user-permissions-status',
    scopedSlots: {
      filterDropdown: 'filterDropdownPoint',
      filterIcon: 'filterIconPoint',
      customRender: 'status_success',
    },
    onFilter: (value, record) =>
      record.Status
        ? record.Status.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInputStamp.focus()
        }, 0)
      }
    },
  },
]

const init = { RowKey: '', Name: 'All' }
const init_a = { ID: '', Name: 'All' }

export default {
  components: {
    ExportExcel,
  },
  mixins: [Mixin],
  props: {
    data: Object,
    show: Boolean,
  },
  data() {
    return {
      defaultDate: null,
      isCompany: false,
      currentLocale: Locale.getLocaleShort(),
      //Stamp
      columnsStamp,
      dataStamp: [],
      totalStamp: null,
      currentStamp: 0,
      search_stamp: {
        start_date: null,
        end_date: null,
        branch: [init],
      },
      startDateStamp: null,
      endDateStamp: null,
      branchStamp: '',
      searchInputStamp: null,
      searchTextStamp: '',
      searchedColumnStamp: '',
      dataExportStamp: [],
      pageSizeStamp: 30,

      //Permissions
      columnsPermissions,
      dataPermissions: [],
      totalPermissions: null,
      currentPermissions: 0,
      search_permissions: {
        start_date: null,
        end_date: null,
        branch: [init],
        campaign: [init],
      },
      startDatePermissions: null,
      endDatePermissions: null,
      branchPermissions: '',
      campaignPermissions: '',
      searchInputPermissions: null,
      searchTextPermissions: '',
      searchedColumnPermissions: '',
      dataExportPermissions: [],
      pageSizePermissions: 30,

      //Point
      columnsPoint,
      dataPoint: [],
      totalPoint: null,
      currentPoint: 0,
      search_point: {
        start_date: null,
        end_date: null,
        branch: [init],
      },
      startDatePoint: null,
      endDatePoint: null,
      branchPoint: '',
      searchInputPoint: null,
      searchTextPoint: '',
      searchedColumnPoint: '',
      dataExportPoint: [],
      showModalTransactions: false,
      pageSizePoint: 30,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      storeType: null,
    }
  },
  watch: {
    show(newVal) {
      if (newVal == true) {
        Promise.all([this.getCRMPlusProfile()]).then(() => {
          this.getBranchList(), this.getCampaignList(), this.ModalShow(newVal)
        })
        this.startDateStamp = null
        this.endDateStamp = null
        this.startDatePermissions = null
        this.endDatePermissions = null
        this.startDatePoint = null
        this.endDatePoint = null
        this.dataStamp = []
        this.dataPermissions = []
        this.dataPoint = []
      }
    },
    startDatePermissions(newVal) {
      if (newVal) {
        this.endDatePermissions = newVal
      }
    },
  },
  created() {
    this.setLocale()
  },
  beforeCreate() {
    this.formpoint = this.$form.createForm(this, {
      name: 'formpoin',
    })
    this.formstamp = this.$form.createForm(this, {
      name: 'formstamp',
    })
    this.formpermissions = this.$form.createForm(this, {
      name: 'formpermissions',
    })
  },
  methods: {
    setLocale() {
      _.forEach(this.columnsStamp, column => {
        column.title = this.lbl[column.key]
      })

      _.forEach(this.columnsPermissions, column => {
        column.title = this.lbl[column.key]
      })

      _.forEach(this.columnsPoint, column => {
        column.title = this.lbl[column.key]
      })
    },
    ModalShow(newVal) {
      this.showModalTransactions = newVal
    },
    changeTab(e) {
      if (e === '1') {
        Account.bzbsAnalyticTracking(
          'home_page',
          'dashboard_details',
          'click_transactions_point',
          'on click',
        )
        this.startDateStamp = null
        this.endDateStamp = null
        this.startDatePermissions = null
        this.endDatePermissions = null
        this.dataStamp = []
        this.dataPermissions = []
      }
      if (e === '2') {
        Account.bzbsAnalyticTracking(
          'home_page',
          'dashboard_details',
          'click_transactions_stamp',
          'on click',
        )
        this.startDatePermissions = null
        this.endDatePermissions = null
        this.startDatePoint = null
        this.endDatePoint = null
        this.dataPoint = []
        this.dataPermissions = []
      }
      if (e === '3') {
        Account.bzbsAnalyticTracking(
          'home_page',
          'dashboard_details',
          'click_transactions_redemption',
          'on click',
        )
        this.startDateStamp = null
        this.endDateStamp = null
        this.startDatePoint = null
        this.endDatePoint = null
        this.dataPoint = []
        this.dataStamp = []
      }
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            this.isCompany = res.data.IsCompany
            this.storeType = res.data.StoreType
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    getBranchList() {
      let params = {}
      return new Promise(resolve => {
        BzbsDashboard.apiGetUIBranch(params)
          .then(res => {
            var data = res.data.sort(function (x, y) {
              return x.Timestamp - y.Timestamp
            })

            data = _.reduce(
              data,
              (unique, o) => {
                if (!unique.some(obj => obj.Name === o.Name)) {
                  unique.push(o)
                }
                return unique
              },
              [],
            )

            var result = _.concat(init, data)

            const selected = _.map(result, e => {
              return { id: e.RowKey, name: e.Name }
            })
            this.search_stamp.branch = selected
            this.search_permissions.branch = selected
            this.search_point.branch = selected
            resolve(res)
          })
          .catch(error => {
            console.log('apiGetUIBranch error', error)
            resolve(error)
          })
      })
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getCampaignListAll()
          .then(res => {
            var result = _.concat(init_a, res.data)
            this.search_permissions.campaign = _.map(result, e => {
              return { id: e.ID, name: e.Name }
            })
            resolve(res)
          })
          .catch(error => {
            console.log('getCampaignListAll error', error)
            resolve(error)
          })
      })
    },
    TransactionsClose() {
      this.showModalTransactions = false
      this.$emit('changeTransactions', false)
    },

    //#region Stamp
    handleSearchStamp() {
      console.log('handleSearchStamp')
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_stamp_search',
        'on click',
      )
      this.getListStamp()
    },
    onChangePageStamp(page) {
      this.currentStamp = page
    },
    itemRenderStamp(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    getListStamp() {
      this.handleLoading(true)
      let params = {}
      if (this.branchStamp != '' && this.branchStamp != null) {
        params = {
          type: 'stamp',
          branchid: this.branchStamp,
          startdate: moment(this.startDateStamp).format('YYYY-MM-DD'),
          enddate: moment(this.endDateStamp).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      } else {
        params = {
          type: 'stamp',
          startdate: moment(this.startDateStamp).format('YYYY-MM-DD'),
          enddate: moment(this.endDateStamp).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetWalletReport(params)
          .then(res => {
            this.handleLoading(false)
            console.log('apiGetWalletReport', res)

            if (res.data.data.length > 0) {
              if (this.currentStamp == 0) {
                this.currentStamp = 1
              }
            } else {
              this.currentStamp = 0
            }

            this.totalStamp = res.data.data.length
            this.dataExportStamp = res.data.data
            this.genPageStamp()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetWalletReport error', error)
            resolve(error)
          })
      })
    },
    genPageStamp() {
      this.dataStamp = this.dataExportStamp
    },
    exportExcelStamp() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_stamp_download',
        'on click',
      )
      this.handleLoading(true)

      let locale = Locale.getLocaleCode()
      if (locale != 'th') locale = 'en'
      moment.locale(locale)

      const data = _.map(this.dataExportStamp, item => {
        var date = ''
        if (
          item.CreateDate != null &&
          item.CreateDate != '-' &&
          item.CreateDate != ''
        ) {
          var format = moment.unix(item.CreateDate).utc().format('YYYY-MM-DD')
          date = new Date(format)
        } else {
          date = '-'
        }
        var CustomerId = this.lbl['dashboard-transactions-user-id']
        var LineId = this.lbl['dashboard-insight-line-user-id-header']
        var TransactionId = this.lbl['dashboard-transactions-list']
        var CreateDate = this.lbl['dashboard-transactions-date-time']
        var BranchName = this.lbl['dashboard-transactions-branch-name']
        var Amount = this.lbl['dashboard-transactions-amount']
        var Points = this.lbl['dashboard-transactions-quantity-stamps']
        var Description = this.lbl['dashboard-transactions-reference-code']
        // var Description2 = this.lbl['dashboard-transactions-reference-code2']
        var Name = this.lbl['user-permissions-name']
        var ContactNumber =
          this.lbl['company-setup-branch-contact-number-placeholder']
        var Status = this.lbl['user-permissions-status']

        return {
          [CustomerId]: item.CustomerId ? item.CustomerId : '-',
          [LineId]: item.LineId ? item.LineId : '-',
          [Name]: item.Name ? item.Name : '-',
          [ContactNumber]: item.ContactNumber ? item.ContactNumber : '-',
          [TransactionId]: item.TransactionId ? item.TransactionId : '-',
          [CreateDate]: date ? date : '-',
          [BranchName]: item.BranchName ? item.BranchName : '-',
          [Amount]: { t: 'n', v: item.Amount, z: '#,##0.00' },
          [Points]: { t: 'n', v: item.StampCount, z: '0' },
          [Description]: item.Description ? item.Description : '-',
          [Status]: item.Status ? item.Status : '-',
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)

        var wscols = [
          { wch: 44 },
          { wch: 25 },
          { wch: 12 },
          { wch: 25 },
          { wch: 12 },
          { wch: 30 },
          { wch: 12 },
          { wch: 12 },
          { wch: 15 },
          { wch: 12 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )

          var ws_cols = [
            { wch: 44 },
            { wch: 25 },
            { wch: 12 },
            { wch: 25 },
            { wch: 12 },
            { wch: 30 },
            { wch: 12 },
            { wch: 12 },
            { wch: 15 },
            { wch: 12 },
          ]
          data_WS['!cols'] = ws_cols

          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
      this.handleLoading(false)
    },
    disabledStartDateStamp(startValue) {
      const endValue = this.endDateStamp
      if (!startValue || !endValue) {
        return startValue && startValue >= Date.now()
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDateStamp(endValue) {
      const startValue = this.startDateStamp
      if (!endValue || !startValue) {
        return endValue && endValue >= Date.now()
      }
      return (
        startValue.valueOf() >= endValue.valueOf() || endValue >= Date.now()
      )
    },
    handleTableSearchStamp(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchTextStamp = selectedKeys[0]
      this.searchedColumnStamp = dataIndex
    },
    handleTableResetStamp(clearFilters) {
      clearFilters()
      this.searchTextStamp = ''
    },
    //#endregion Stamp

    //#region Permissions
    handleSearchPermissions() {
      console.log('handleSearchPermissions')
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_redemption_search',
        'on click',
      )
      this.getListPermissions()
    },
    onChangePagePermissions(page) {
      this.currentPermissions = page
    },
    itemRenderPermissions(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    getListPermissions() {
      this.handleLoading(true)
      let params = {}
      if (this.branchPermissions != '' && this.branchPermissions != null) {
        params = {
          branchid: this.branchPermissions,
          startdate: moment(this.startDatePermissions).format('YYYY-MM-DD'),
          enddate: moment(this.endDatePermissions).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      } else {
        params = {
          branchid: Vue.bzbsConfig.businessRule.default.terminalId,
          startdate: moment(this.startDatePermissions).format('YYYY-MM-DD'),
          enddate: moment(this.endDatePermissions).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetRedeemTransaction(params)
          .then(res => {
            this.handleLoading(false)
            console.log('apiGetRedeemTransaction', res)

            if (res.data.data.length > 0) {
              if (this.currentPermissions == 0) {
                this.currentPermissions = 1
              }
            } else {
              this.currentPermissions = 0
            }

            this.totalPermissions = res.data.data.length
            this.dataExportPermissions = res.data.data
            this.genPagePermissions()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetRedeemTransaction error', error)
            resolve(error)
          })
      })
    },
    genPagePermissions() {
      if (this.campaignPermissions != '' && this.campaignPermissions != null) {
        this.dataExportPermissions = _.filter(
          this.dataExportPermissions,
          item => {
            if (item.CampaignId == this.campaignPermissions) return item
          },
        )
      } else {
        this.dataExportPermissions = this.dataExportPermissions
      }

      this.totalPermissions = this.dataExportPermissions.length
      this.dataPermissions = this.dataExportPermissions
    },
    exportExcelPermissions() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_redemption_download',
        'on click',
      )
      this.handleLoading(true)

      const data = _.map(this.dataExportPermissions, item => {
        var date = ''
        if (
          item.CreateDate != null &&
          item.CreateDate != '-' &&
          item.CreateDate != ''
        ) {
          var format = moment.unix(item.CreateDate).utc().format('YYYY-MM-DD')
          date = new Date(format)
        } else {
          date = '-'
        }
        var CustomerId = this.lbl['dashboard-transactions-user-id']
        var LineId = this.lbl['dashboard-insight-line-user-id-header']
        var TransactionId = this.lbl['dashboard-transactions-list']
        var CreateDate = this.lbl['dashboard-transactions-date-time']
        var BranchName = this.lbl['dashboard-transactions-branch-name']
        var CampaignName = this.lbl['dashboard-transactions-campaign']
        var PointPerUnit = this.lbl['dashboard-transactions-point']
        var Serial = this.lbl['dashboard-transactions-campaign-code']
        var Name = this.lbl['user-permissions-name']
        var ContactNumber =
          this.lbl['company-setup-branch-contact-number-placeholder']
        var Status = this.lbl['user-permissions-status']

        let FullName = '-'
        if (item.FirstName && item.LastName) {
          FullName = item.FirstName + ' ' + item.LastName
        } else if (item.FirstName && !item.LastName) {
          FullName = item.FirstName
        } else if (!item.FirstName && item.LastName) {
          FullName = item.LastName
        } else {
          FullName = '-'
        }

        return {
          [CustomerId]: item.CustomerId ? item.CustomerId : '-',
          [LineId]: item.LineId ? item.LineId : '-',
          [Name]: FullName,
          [ContactNumber]: item.ContactNumber ? item.ContactNumber : '-',
          [TransactionId]: item.TransactionId ? item.TransactionId : '-',
          [CreateDate]: date ? date : '-',
          [BranchName]: item.BranchName ? item.BranchName : '-',
          [CampaignName]: item.CampaignName ? item.CampaignName : '-',
          [PointPerUnit]: { t: 'n', v: item.PointPerUnit, z: '0' },
          [Serial]: item.Serial ? item.Serial : '-',
          [Status]: item.Status == 3 ? this.lbl['complete-label'] : '-',
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        var wscols = [
          { wch: 44 },
          { wch: 25 },
          { wch: 12 },
          { wch: 25 },
          { wch: 12 },
          { wch: 30 },
          { wch: 30 },
          { wch: 7 },
          { wch: 12 },
          { wch: 12 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          var ws_cols = [
            { wch: 44 },
            { wch: 25 },
            { wch: 12 },
            { wch: 25 },
            { wch: 12 },
            { wch: 30 },
            { wch: 30 },
            { wch: 7 },
            { wch: 12 },
            { wch: 12 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
      this.handleLoading(false)
    },
    disabledStartDatePermissions(startValue) {
      const endValue = this.endDatePoint
      if (!startValue || !endValue) {
        return startValue && startValue >= Date.now()
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDatePermissions(endValue) {
      var date = new Date(this.startDatePermissions)
      var firstDay = date
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      if (lastDay >= new Date()) {
        lastDay = new Date()
      }

      const startValue = firstDay
      if (!endValue || !startValue) {
        return endValue && endValue >= lastDay
      }
      return startValue.valueOf() > endValue.valueOf() || endValue >= lastDay
    },
    handleTableSearchPermissions(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchTextPermissions = selectedKeys[0]
      this.searchedColumnPermissions = dataIndex
    },
    handleTableResetPermissions(clearFilters) {
      clearFilters()
      this.searchTextPermissions = ''
    },
    //#endregion Permissions

    //#region Point
    handleSearchPoint() {
      console.log('handleSearchPoint')
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_point_search',
        'on click',
      )
      this.getListPoint()
    },
    onChangePagePoint(page) {
      this.currentPoint = page
    },
    itemRenderPoint(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    getListPoint() {
      this.handleLoading(true)
      let params = {}
      if (this.branchPoint != '' && this.branchPoint != null) {
        params = {
          type: 'earn',
          branchid: this.branchPoint,
          startdate: moment(this.startDatePoint).format('YYYY-MM-DD'),
          enddate: moment(this.endDatePoint).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      } else {
        params = {
          type: 'earn',
          branchid: 'all',
          startdate: moment(this.startDatePoint).format('YYYY-MM-DD'),
          enddate: moment(this.endDatePoint).format('YYYY-MM-DD'),
          iscountpage: false,
        }
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetWalletReportBuzzportal(params)
          .then(res => {
            this.handleLoading(false)
            console.log('apiGetWalletReportBuzzportal : ', res)

            if (res.data.data.length > 0) {
              if (this.currentPoint == 0) {
                this.currentPoint = 1
              }
            } else {
              this.currentPoint = 0
            }

            this.totalPoint = res.data.data.length
            this.dataExportPoint = res.data.data
            this.genPagePoint()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetWalletReportBuzzportal error', error)
            resolve(error)
          })
      })
    },
    genPagePoint() {
      this.dataPoint = this.dataExportPoint
    },
    exportExcelPoint() {
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_transactions_point_download',
        'on click',
      )
      this.handleLoading(true)

      const data = _.map(this.dataExportPoint, item => {
        var date = ''
        if (item.Date != null && item.Date != '-' && item.Date != '') {
          date = this.datetimeShortFormatUTC(item.Date)
        } else {
          date = '-'
        }
        var CustomerId = this.lbl['dashboard-transactions-user-id']
        var LineId = this.lbl['dashboard-insight-line-user-id-header']
        var TransactionId = this.lbl['dashboard-transactions-list']
        var CreateDate = this.lbl['dashboard-transactions-date-time']
        var BranchName = this.lbl['dashboard-transactions-branch-name']
        var Amount = this.lbl['dashboard-transactions-quantity']
        var Points = this.lbl['dashboard-transactions-earn-points']
        var ReferenceCode = this.lbl['dashboard-transactions-reference-code']
        var Source = this.lbl['dashboard-transactions-reference-source']
        var Name = this.lbl['user-permissions-name']
        var ContactNumber =
          this.lbl['company-setup-branch-contact-number-placeholder']
        var Status = this.lbl['user-permissions-status']

        return {
          [CustomerId]: item.UserID ? item.UserID : '-',
          [LineId]: item.LineId ? item.LineId : '-',
          [Name]: item.Name ? item.Name : '-',
          [ContactNumber]: item.ContactNumber ? item.ContactNumber : '-',
          [TransactionId]: item.TransactionID ? item.TransactionID : '-',
          [CreateDate]: date ? date : '-',
          [BranchName]: item.Branch ? item.Branch : '-',
          [Amount]: { t: 'n', v: item.Amount, z: '#,##0.00' },
          [Points]: { t: 'n', v: item.GivePoints, z: '0' },
          [ReferenceCode]: item.ReferenceCode ? item.ReferenceCode : '-',
          [Source]: item.Source ? item.Source : '-',
          [Status]: item.Status ? item.Status : '-',
        }
      })

      if (data.length <= 10000) {
        const dataWS = XLSX.utils.json_to_sheet(data)
        var wscols = [
          { wch: 44 },
          { wch: 25 },
          { wch: 12 },
          { wch: 25 },
          { wch: 12 },
          { wch: 30 },
          { wch: 12 },
          { wch: 12 },
          { wch: 15 },
          { wch: 15 },
          { wch: 12 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )

          var ws_cols = [
            { wch: 44 },
            { wch: 25 },
            { wch: 12 },
            { wch: 25 },
            { wch: 12 },
            { wch: 30 },
            { wch: 12 },
            { wch: 12 },
            { wch: 15 },
            { wch: 15 },
            { wch: 12 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }
      this.handleLoading(false)
    },
    disabledStartDatePoint(startValue) {
      const endValue = this.endDatePoint
      if (!startValue || !endValue) {
        return startValue && startValue >= Date.now()
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDatePoint(endValue) {
      const startValue = this.startDatePoint
      if (!endValue || !startValue) {
        return endValue && endValue >= Date.now()
      }
      return (
        startValue.valueOf() >= endValue.valueOf() || endValue >= Date.now()
      )
    },
    handleTableSearchPoint(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchTextPoint = selectedKeys[0]
      this.searchedColumnPoint = dataIndex
    },
    handleTableResetPoint(clearFilters) {
      clearFilters()
      this.searchTextPoint = ''
    },
    //#endregion Point

    //#region onlyNumber
    onlyNumberPoint($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberStamp($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberPermissions($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    //#endregion onlyNumber
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
.modal-Transactions {
  .lbl-error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.6px;
    color: $color-red-52;
  }

  .icon-color {
    color: $color-orange-1 !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $color-orange-1 !important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $color-orange-1 !important;
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    background-color: $color-orange-1 !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }

  i.anticon.anticon-left {
    vertical-align: -2px;
  }

  i.anticon.anticon-right {
    vertical-align: -2px;
  }

  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    margin-top: -9px;
    line-height: 0px;
  }

  .ant-select-arrow {
    margin-top: -12px;
  }

  form.ant-form.ant-form-horizontal {
    padding: 10px 50px 10px 50px;
    border-bottom-style: inset;
  }

  .button-search {
    margin-top: 47px;
    height: 40px;
    color: #fff !important;
    background-color: $color-orange-1 !important;
    border-color: $color-orange-1 !important;
    float: left;
    min-width: 50%;
    align-items: center;
    .anticon {
      font-size: 20px;
      color: #ffffff;
    }
  }

  .modal-header {
    padding: 0rem 1rem 1rem 1rem !important;
    border-bottom: 1px solid #dee2e6;
  }

  .div-import {
    float: right;
    margin-right: 4px;
  }

  .ico-excel {
    height: 1.5vw;
    padding: 0.25vw;
  }

  .ico-pdf {
    background-color: transparent !important;
    padding: 0vw !important;
  }

  .button-import {
    height: 45px;
    padding: 5px;
    width: 130px;
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #000;
  }

  .button-import:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .d-box-search {
    display: -webkit-box;
    padding: 20px 10px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  .ant-tabs-nav {
    float: left;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 20px 16px;
  }

  .ant-form {
    .anticon {
      font-size: 15px !important;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .d-box-search {
      display: initial;
      padding: 0px;
      color: rgba(0, 0, 0, 0.85);
    }

    .div-import {
      float: left;
      margin-right: 4px;
    }

    .ico-excel {
      height: 8.5vw;
      padding: 1.25vw;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 568px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .d-box-search {
      display: initial;
      padding: 0px;
      color: rgba(0, 0, 0, 0.85);
    }

    .div-import {
      float: left;
      margin-right: 4px;
    }

    .ico-excel {
      height: 4.5vw;
      padding: 0.5vw;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      height: 4.5vw;
      padding: 0.5vw;
    }
  }

  //แนวนอน mobile (iPhone x)
  @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ant-modal {
      width: 90% !important;
    }

    .ico-excel {
      height: 4vw;
      padding: 0.6vw;
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1023px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      height: 4vw;
      padding: 0.6vw;
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      height: 3.2vw;
      padding: 0.4vw;
    }
  }

  //แนวตั้ง iPad Pro
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: portrait) {
    .ico-excel {
      height: 3.2vw;
      padding: 0.6vw;
    }
  }

  //แนวนอน iPad Pro
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: landscape) {
    .ico-excel {
      height: 2.5vw;
      padding: 0.4vw;
    }
  }
}
</style>
