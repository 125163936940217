<template>
  <div :class="['mainBGAnnouncement', isOpenModal ? 'open' : '']">
    <div>
      <div class="btn-cancel d-flex justify-end">
        <div class="border-btn-cancel d-flex justify-center align-center">
          <a id="Announcement_button_cancel" @click="onCancel()">
            <img src="@/assets/images/selfonboard/close-back.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="mainModal">
        <VueSlickCarousel
          v-if="data.length > 0"
          ref="innerSlider"
          :arrows="false"
          :dots="true"
          :rows="1"
          :slidesPerRow="slidesNum"
        >
          <div v-for="(item, index) in data" :key="index">
            <div class="div-box-image">
              <img
                v-if="item.CoverImages"
                class="img-port"
                :src="coverImages(item.CoverImages)"
                alt=""
              />
            </div>
            <div class="div-box-content">
              <div class="title">
                {{ item.Title }}
              </div>
              <div class="h-details mt-3">
                <div class="details" v-html="item.Contents"></div>
              </div>
              <div class="btn-seemore w-100">
                <button class="primarybtn w-100" @click="handleSeemore()">
                  {{ lbl['user-permissions-see-more'] }}
                </button>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="div-box-action d-flex justify-end mb-3">
          <a class="mr-2 d-flex align-center" type="submit" @click="handlePrev"
            ><a-icon type="left" /> {{ lbl['main-btn-previos'] }}</a
          >
          <a class="ml-2 d-flex align-center" type="submit" @click="handleNext"
            >{{ lbl['main-btn-next'] }} <a-icon type="right"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  mixins: [Mixin],
  components: {
    VueSlickCarousel,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    isOpenModal: {
      type: Boolean,
    },
    onCancel: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: '24',
    },
  },
  data() {
    return {
      slidesNum: 1,
    }
  },
  methods: {
    handleSeemore() {
      localStorage.setItem('stampDateAnnouncement', new Date())
      this.$router.push({ name: 'Announcement' })
    },
    handlePrev() {
      console.log('handlePrev : ')
      this.$refs.innerSlider.prev()
    },
    handleNext() {
      console.log('handleNext : ')
      this.$refs.innerSlider.next()
    },
    coverImages(Images) {
      if (Images) {
        let imgList = JSON.parse(Images)
        let img = imgList[0].Url + '?v=' + new Date().getTime()
        return img
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.mainBGAnnouncement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black-b3;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 650px;
    width: 100%;
    background-color: $color-white;
    border-radius: 20px;
    overflow: hidden;
    padding: 0;
    z-index: 9999;
    text-align: left;
    .title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: $color-grey-42;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .h-details {
      min-height: 220px;
      .details {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $text-dark-gray;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
      }
    }
    .btnContainer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
    }
  }
  .div-contact {
    font-weight: 500;
    color: $color-link;
  }
  .btn-cancel {
    z-index: 1;
    margin-right: -40px;
    .border-btn-cancel {
      background-color: $color-white;
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
  }
  .div-box-image {
    height: 230px;
    width: 650px;
    img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .div-box-content {
    padding: 20px 30px;
  }
  .div-box-action {
    padding: 0 30px;
    a {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $color-grey-5e;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (min-device-width: 625px) and (max-device-width: 767px) and (orientation: portrait) {
    .mainModal {
      width: calc(100vw - 70px);
      .title {
        font-size: 16px;
      }
      .h-details {
        .details {
          font-size: 12px;
        }
      }

      .div-box-action {
        a {
          font-size: 12px;
        }
      }
    }
    .btn-cancel {
      margin-right: -30px;
      .border-btn-cancel {
        background-color: $color-white;
        width: 30px;
        height: 30px;
        border-radius: 50px;
      }
    }
    .div-box-image {
      height: 150px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  @media only screen and (max-device-width: 624px) and (orientation: portrait) {
    .mainModal {
      width: 100vw;
      .title {
        font-size: 16px;
      }
      .h-details {
        .details {
          font-size: 12px;
        }
      }

      .div-box-action {
        a {
          font-size: 12px;
        }
      }
    }
    .btn-cancel {
      z-index: 9;
      position: relative;
      margin-bottom: -40px;
      margin-right: 8px;
      .border-btn-cancel {
        background-color: $color-white;
        width: 30px;
        height: 30px;
        border-radius: 50px;
      }
    }
    .div-box-image {
      height: 150px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 896px) and (orientation: landscape) {
    &.open {
      visibility: visible;
      opacity: 1;
      overflow-x: auto;
      padding: 350px 0 600px 0;
    }

    .mainModal {
      width: calc(100vw - 70px);

      .title {
        font-size: 16px;
      }
      .h-details {
        .details {
          font-size: 12px;
        }
      }

      .div-box-action {
        a {
          font-size: 12px;
        }
      }
    }
    .btn-cancel {
      margin-right: -30px;
      .border-btn-cancel {
        background-color: $color-white;
        width: 30px;
        height: 30px;
        border-radius: 50px;
      }
    }
    .div-box-image {
      height: 200px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/style/base/common.scss';
.mainBGAnnouncement {
  .slick-dots {
    position: absolute;
    width: 50%;
    list-style: none;
    text-align: left;
    padding-left: 30px;
    bottom: -20px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: $color-orange-2;
  }
}
</style>
