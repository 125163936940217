<template>
  <div :class="['mainBGPackageChanged', isOpenModal ? 'open' : '']">
    <div>
      <div class="mainModal">
        <div class="title">
          <div class="titleText" :style="{ 'font-size': fontSize + 'px' }">
            {{ title }}
          </div>
        </div>
        <div class="row d-flex align-center justify-center my-3 mx-4">
          <div
            class="
              col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12
              d-flex
              align-center
              justify-center
            "
          >
            <div class="box-img-pagkage">
              <img :src="imgHistory" class="w-100 h-100" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 px-0">
            <div class="d-flex align-center justify-center my-2">
              <img src="@/assets/images/announcement/arrow.svg" alt="" />
            </div>
          </div>
          <div
            class="
              col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12
              d-flex
              align-center
              justify-center
            "
          >
            <div class="box-img-pagkage">
              <img :src="imgCurrent" class="w-100 h-100" alt="" />
            </div>
          </div>
        </div>
        <div class="details text-center">
          {{ lbl['change-your-package-crmplus'] }}
          <strong>{{ details }}</strong>
        </div>
        <div class="details text-center">
          {{ lbl['life-time'] }} : <strong>{{ lifeTime }}</strong>
          <span class="text-capitalize"> {{ lbl['fgf-day'] }}</span>
        </div>
        <div class="details text-center">
          {{ lbl['next-payment-on'] }} : <strong>{{ payment }}</strong>
        </div>
        <div class="text-center my-4">
          <!-- <a class="btn-view-detail" @click="onView()">{{
            lbl['selfonboard-view-package-detail']
          }}</a> -->
        </div>
        <div class="text-center">
          <button
            id="packagechanged_cancel_button"
            class="outlineprimary w-100"
            @click="onCancel()"
          >
            {{ lbl['alert-box-error-close-button'] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    title: {
      type: String,
    },
    details: {
      type: String,
    },
    lifeTime: {
      type: String,
    },
    payment: {
      type: String,
    },
    imgHistory: {
      type: String,
    },
    imgCurrent: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
    },
    onCancel: {
      type: Function,
    },
    onView: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: '24',
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.mainBGPackageChanged {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black-b3;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    width: auto;
    background-color: $color-white;
    border-radius: 8px;
    padding: 24px;
    z-index: 9999;
    text-align: left;
    .title {
      text-align: center;
      .icon {
        width: 90px;
        height: 90px;
        padding: 10px;
        border-radius: 4px;
        background-color: $color-red-f3;
        &.danger {
          background-color: $color-red-e0;
        }
        &.question {
          background-color: $color-blue-e6;
        }
      }
      .titleText {
        margin-left: 8px;
      }
    }
    .details {
      color: $color-grey-75;
      margin-top: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.15px;
      strong {
        color: $color-grey-42;
      }
    }
  }
  .box-img-pagkage {
    border: 1px solid $color-grey-e4;
    height: 150px;
    min-width: 150px;
    max-width: 150px;
    border-radius: 15px;
    overflow: hidden;
  }
  .btn-view-detail {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $color-orange-2;
  }
}
</style>
