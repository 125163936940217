<template>
  <div class="Insights">
    <a-modal
      v-model="showModalInsight"
      :footer="null"
      class="modal-Insights"
      :class="[currentLocale]"
      :afterClose="InsightsClose"
      destroyOnClose
    >
      <div class="modal-header">
        <h5 class="modal-title">
          <span class="svg-icon svg-icon-danger svg-icon-2x"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M12.7442084,3.27882877 L19.2473374,6.9949025 C19.7146999,7.26196679 20.003129,7.75898194 20.003129,8.29726722 L20.003129,15.7027328 C20.003129,16.2410181 19.7146999,16.7380332 19.2473374,17.0050975 L12.7442084,20.7211712 C12.2830594,20.9846849 11.7169406,20.9846849 11.2557916,20.7211712 L4.75266256,17.0050975 C4.28530007,16.7380332 3.99687097,16.2410181 3.99687097,15.7027328 L3.99687097,8.29726722 C3.99687097,7.75898194 4.28530007,7.26196679 4.75266256,6.9949025 L11.2557916,3.27882877 C11.7169406,3.01531506 12.2830594,3.01531506 12.7442084,3.27882877 Z M12,14.5 C13.3807119,14.5 14.5,13.3807119 14.5,12 C14.5,10.6192881 13.3807119,9.5 12,9.5 C10.6192881,9.5 9.5,10.6192881 9.5,12 C9.5,13.3807119 10.6192881,14.5 12,14.5 Z"
                  fill="#F64E60"
                ></path>
              </g>
            </svg>
          </span>
          User Insights
          <span class="last-update ml-4">
            *{{ lbl['last-update'] }} {{ lastUpdate }}</span
          >
        </h5>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="div-import pt-3">
            <ExportExcel
              :text="lbl['dashboard-insight-export-excel-button']"
              @clickButton="exportExcel()"
            />
          </div>
        </div>
      </div>
      <a-table
        row-key="userId"
        :columns="columnsInsight"
        :data-source="dataInsight"
        :pagination="{
          pageSize: pageSize,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
          pageSizeOptions: pageSizeOptions,
          defaultCurrent: 1,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onChange: onChangePage,
        }"
        class="pt-3"
        :customRow="openHistory"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`${lbl['dashboard-insight-search-button']} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              e => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            {{ lbl['dashboard-insight-search-button'] }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleTableReset(clearFilters)"
          >
            {{ lbl['confirm-box-cancel-button'] }}
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="sex" slot-scope="text, record">
          <span v-if="record.gender == 'male'">{{ lbl['Male'] }}</span>
          <span v-else-if="record.gender == 'female'">{{ lbl['Female'] }}</span>
          <span v-else>{{ lbl['Other'] }}</span>
        </template>
        <template slot="date" slot-scope="text">
          {{ text ? dateShortFormatUTC(text) : '-' }}
        </template>
        <template slot="isdata" slot-scope="text">
          {{ text ? text : '-' }}
        </template>
        <template slot="isdata_connect" slot-scope="boolean">
          {{
            boolean
              ? lbl['dashboard-insight-user-connect']
              : lbl['dashboard-insight-user-unconnect']
          }}
        </template>
        <template slot="isdata_number" slot-scope="text">
          {{ text ? text : '0' }}
        </template>
        <template slot="isdata_digi" slot-scope="text">
          {{ text ? text : '0.00' }}
        </template>
      </a-table>
    </a-modal>

    <InsightsHistory
      :show="showModal"
      :data="dataInsightsHistory"
      @changeInsightsHistory="ChangeInsightsHistory($event)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsDashboard from '@/core/Campaign/service/BzbsDashboard'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import XLSX from 'xlsx'
import moment from 'moment'
import InsightsHistory from '@/module/components/dashboard/popup/InsightsHistory'
import ExportExcel from '@/module/components/dashboard/botton/ExportExcel'
import Locale from '@/helper/locale.js'
import InfiniteHelper from '@/helper/InfiniteHelper.js'
import Vue from 'vue'

export default {
  mixins: [Mixin],
  components: {
    InsightsHistory,
    ExportExcel,
  },
  props: {
    data: Object,
    show: Boolean,
  },
  data() {
    return {
      dataInsight: [],
      dataDateInsight: null,
      totalInsight: null,
      currentInsight: 0,
      userId: 0,
      showModal: false,
      showModalInsight: false,
      dataInsightsHistory: {},
      pageSize: 20,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      searchInput: null,
      lastQuarter: '',
      currentLocale: 'th',
      referenceCustomName: '',
      referenceCustomName2: '',
      columnsInsight: Vue.bzbsConfig.businessRule.webFor.PH
        ? [
            {
              title: 'UserID',
              dataIndex: 'userId',
              key: 'dashboard-insight-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.userId
                  ? record.userId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Line User Id',
              dataIndex: 'lineUserId',
              key: 'dashboard-insight-line-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.lineUserId
                  ? record.lineUserId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Verify Code',
              dataIndex: 'verifyCode',
              key: 'dashboard-insight-verify-code-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.verifyCode
                  ? record.verifyCode
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Full Name',
              dataIndex: 'name',
              key: 'dashboard-insight-user-info-name',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.name
                  ? record.name
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Gender',
              dataIndex: 'gender',
              key: 'dashboard-insight-user-info-sex',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'sex',
              },
              onFilter: (value, record) =>
                record.gender
                  ? record.gender
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Mobile Number',
              dataIndex: 'mobileNumber',
              key: 'dashboard-insight-user-info-mobile-number',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.mobileNumber
                  ? record.mobileNumber
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Status',
              dataIndex: 'InactiveStatus',
              key: 'imp-usr-dtl-status',
              filters: [],
              onFilter: (value, record) =>
                record.InactiveStatus.indexOf(value) === 0,
            },
            {
              title: 'Reference Info',
              dataIndex: 'referenceInfo',
              key: 'dashboard-insight-reference-info-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo
                  ? record.referenceInfo
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Reference Info2',
              dataIndex: 'referenceInfo2',
              key: 'dashboard-insight-reference-info-2-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo2
                  ? record.referenceInfo2
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Member level',
              dataIndex: 'memberLevel',
              key: 'dashboard-insight-member-level',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.memberLevel
                  ? record.memberLevel
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Date of Birth',
              dataIndex: 'birthday',
              key: 'dashboard-insight-date-of-birth',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.birthday
                  ? record.birthday
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Age',
              dataIndex: 'age',
              key: 'dashboard-insight-age',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.age
                  ? record.age
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Register On',
              dataIndex: 'registeredOn',
              key: 'dashboard-insight-user-info-register',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.registeredOn
                  ? record.registeredOn
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'dashboard-insight-user-info-email',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.email
                  ? record.email
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Freq / M',
              dataIndex: 'freqLogin',
              key: 'dashboard-insight-user-info-frequency',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqLogin']
                  ? record['freqLogin']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Frequency',
              dataIndex: 'freqloginprevious',
              key: 'dashboard-insight-user-info-frequency-previous',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqloginprevious']
                  ? record['freqloginprevious']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Earn Point / Stamp',
              dataIndex: 'pointEarn',
              key: 'dashboard-insight-user-info-total-earn-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointEarn']
                  ? record['pointEarn']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },

            {
              title: 'Point / Stamp On Hand',
              dataIndex: 'pointOnHand',
              key: 'dashboard-insight-user-info-point-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointOnHand']
                  ? record['pointOnHand']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Point Expired on',
              dataIndex: 'pointExpireLastQuarter',
              key: 'dashboard-insight-point-expired-on',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record.pointExpireLastQuarter
                  ? record.pointExpireLastQuarter
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Spending',
              dataIndex: 'totalSpending',
              key: 'dashboard-insight-user-info-total-spending',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_digi',
              },
              onFilter: (value, record) =>
                record['totalSpending']
                  ? record['totalSpending']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Lastest Transaction',
              dataIndex: 'lastestTransaction',
              key: 'dashboard-insight-user-info-lastest-transaction',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lastestTransaction']
                  ? record['lastestTransaction']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Term and Condition',
              dataIndex: 'termAndCondition',
              key: 'dashboard-insight-user-info-term-and-condition',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['termAndCondition']
                  ? record['termAndCondition']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Privacy Policy',
              dataIndex: 'dataPrivacy',
              key: 'dashboard-insight-user-info-privacy-policy',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['dataPrivacy']
                  ? record['dataPrivacy']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'LINE Marketing',
              dataIndex: 'lineMarketing',
              key: 'dashboard-insight-user-info-line-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lineMarketing']
                  ? record['lineMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'SMS Marketing',
              dataIndex: 'smsMarketing',
              key: 'dashboard-insight-user-info-sms-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['smsMarketing']
                  ? record['smsMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email Marketing',
              dataIndex: 'emailMarketing',
              key: 'dashboard-insight-user-info-email-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['emailMarketing']
                  ? record['emailMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
          ]
        : [
            {
              title: 'UserID',
              dataIndex: 'userId',
              key: 'dashboard-insight-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.userId
                  ? record.userId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Line User Id',
              dataIndex: 'lineUserId',
              key: 'dashboard-insight-line-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.lineUserId
                  ? record.lineUserId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Verify Code',
              dataIndex: 'verifyCode',
              key: 'dashboard-insight-verify-code-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.verifyCode
                  ? record.verifyCode
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Full Name',
              dataIndex: 'name',
              key: 'dashboard-insight-user-info-name',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.name
                  ? record.name
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Gender',
              dataIndex: 'gender',
              key: 'dashboard-insight-user-info-sex',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'sex',
              },
              onFilter: (value, record) =>
                record.gender
                  ? record.gender
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Mobile Number',
              dataIndex: 'mobileNumber',
              key: 'dashboard-insight-user-info-mobile-number',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.mobileNumber
                  ? record.mobileNumber
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Status',
              dataIndex: 'InactiveStatus',
              key: 'imp-usr-dtl-status',
              filters: [],
              onFilter: (value, record) =>
                record.InactiveStatus.indexOf(value) === 0,
            },
            {
              title: 'Reference Info',
              dataIndex: 'referenceInfo',
              key: 'dashboard-insight-reference-info-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo
                  ? record.referenceInfo
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Reference Info2',
              dataIndex: 'referenceInfo2',
              key: 'dashboard-insight-reference-info-2-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo2
                  ? record.referenceInfo2
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Member level',
              dataIndex: 'memberLevel',
              key: 'dashboard-insight-member-level',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.memberLevel
                  ? record.memberLevel
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Date of Birth',
              dataIndex: 'birthday',
              key: 'dashboard-insight-date-of-birth',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.birthday
                  ? record.birthday
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Age',
              dataIndex: 'age',
              key: 'dashboard-insight-age',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.age
                  ? record.age
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Register On',
              dataIndex: 'registeredOn',
              key: 'dashboard-insight-user-info-register',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.registeredOn
                  ? record.registeredOn
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'dashboard-insight-user-info-email',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.email
                  ? record.email
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account Shopee',
              dataIndex: 'accountShopee',
              key: 'dashboard-insight-user-info-account-shopee',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountShopee']
                  ? record['accountShopee']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account LAZADA',
              dataIndex: 'accountLazada',
              key: 'dashboard-insight-user-info-account-lazada',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountLazada']
                  ? record['accountLazada']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account Tiktok',
              dataIndex: 'accountTikTok',
              key: 'dashboard-insight-user-info-account-tiktok',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountTikTok']
                  ? record['accountTikTok']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Freq / M',
              dataIndex: 'freqLogin',
              key: 'dashboard-insight-user-info-frequency',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqLogin']
                  ? record['freqLogin']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Frequency',
              dataIndex: 'freqloginprevious',
              key: 'dashboard-insight-user-info-frequency-previous',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqloginprevious']
                  ? record['freqloginprevious']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Earn Point / Stamp',
              dataIndex: 'pointEarn',
              key: 'dashboard-insight-user-info-total-earn-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointEarn']
                  ? record['pointEarn']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },

            {
              title: 'Point / Stamp On Hand',
              dataIndex: 'pointOnHand',
              key: 'dashboard-insight-user-info-point-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointOnHand']
                  ? record['pointOnHand']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Point Expired on',
              dataIndex: 'pointExpireLastQuarter',
              key: 'dashboard-insight-point-expired-on',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record.pointExpireLastQuarter
                  ? record.pointExpireLastQuarter
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Spending',
              dataIndex: 'totalSpending',
              key: 'dashboard-insight-user-info-total-spending',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_digi',
              },
              onFilter: (value, record) =>
                record['totalSpending']
                  ? record['totalSpending']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Lastest Transaction',
              dataIndex: 'lastestTransaction',
              key: 'dashboard-insight-user-info-lastest-transaction',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lastestTransaction']
                  ? record['lastestTransaction']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Term and Condition',
              dataIndex: 'termAndCondition',
              key: 'dashboard-insight-user-info-term-and-condition',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['termAndCondition']
                  ? record['termAndCondition']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Privacy Policy',
              dataIndex: 'dataPrivacy',
              key: 'dashboard-insight-user-info-privacy-policy',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['dataPrivacy']
                  ? record['dataPrivacy']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'LINE Marketing',
              dataIndex: 'lineMarketing',
              key: 'dashboard-insight-user-info-line-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lineMarketing']
                  ? record['lineMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'SMS Marketing',
              dataIndex: 'smsMarketing',
              key: 'dashboard-insight-user-info-sms-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['smsMarketing']
                  ? record['smsMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email Marketing',
              dataIndex: 'emailMarketing',
              key: 'dashboard-insight-user-info-email-marketing',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['emailMarketing']
                  ? record['emailMarketing']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
          ],
      lastUpdate: '',
      isBeeBanefit: false,
    }
  },
  created() {
    this.setLocale()
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
    if (this.isBeeBanefit) {
      this.columnsInsight = Vue.bzbsConfig.businessRule.webFor.PH
        ? [
            {
              title: 'UserID',
              dataIndex: 'userId',
              key: 'dashboard-insight-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.userId
                  ? record.userId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Line User Id',
              dataIndex: 'lineUserId',
              key: 'dashboard-insight-line-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.lineUserId
                  ? record.lineUserId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Verify Code',
              dataIndex: 'verifyCode',
              key: 'dashboard-insight-verify-code-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.verifyCode
                  ? record.verifyCode
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Full Name',
              dataIndex: 'name',
              key: 'dashboard-insight-user-info-name',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.name
                  ? record.name
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Gender',
              dataIndex: 'gender',
              key: 'dashboard-insight-user-info-sex',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'sex',
              },
              onFilter: (value, record) =>
                record.gender
                  ? record.gender
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Mobile Number',
              dataIndex: 'mobileNumber',
              key: 'dashboard-insight-user-info-mobile-number',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.mobileNumber
                  ? record.mobileNumber
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Status',
              dataIndex: 'InactiveStatus',
              key: 'imp-usr-dtl-status',
              filters: [],
              onFilter: (value, record) =>
                record.InactiveStatus.indexOf(value) === 0,
            },
            {
              title: 'Reference Info',
              dataIndex: 'referenceInfo',
              key: 'dashboard-insight-reference-info-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo
                  ? record.referenceInfo
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Reference Info2',
              dataIndex: 'referenceInfo2',
              key: 'dashboard-insight-reference-info-2-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo2
                  ? record.referenceInfo2
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Date of Birth',
              dataIndex: 'birthday',
              key: 'dashboard-insight-date-of-birth',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.birthday
                  ? record.birthday
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Age',
              dataIndex: 'age',
              key: 'dashboard-insight-age',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.age
                  ? record.age
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Register On',
              dataIndex: 'registeredOn',
              key: 'dashboard-insight-user-info-register',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.registeredOn
                  ? record.registeredOn
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'dashboard-insight-user-info-email',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.email
                  ? record.email
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Freq / M',
              dataIndex: 'freqLogin',
              key: 'dashboard-insight-user-info-frequency',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqLogin']
                  ? record['freqLogin']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Frequency',
              dataIndex: 'freqloginprevious',
              key: 'dashboard-insight-user-info-frequency-previous',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqloginprevious']
                  ? record['freqloginprevious']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Earn Point / Stamp',
              dataIndex: 'pointEarn',
              key: 'dashboard-insight-user-info-total-earn-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointEarn']
                  ? record['pointEarn']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },

            {
              title: 'Point / Stamp On Hand',
              dataIndex: 'pointOnHand',
              key: 'dashboard-insight-user-info-point-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointOnHand']
                  ? record['pointOnHand']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Point Expired on',
              dataIndex: 'pointExpireLastQuarter',
              key: 'dashboard-insight-point-expired-on',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record.pointExpireLastQuarter
                  ? record.pointExpireLastQuarter
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Spending',
              dataIndex: 'totalSpending',
              key: 'dashboard-insight-user-info-total-spending',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_digi',
              },
              onFilter: (value, record) =>
                record['totalSpending']
                  ? record['totalSpending']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Lastest Transaction',
              dataIndex: 'lastestTransaction',
              key: 'dashboard-insight-user-info-lastest-transaction',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lastestTransaction']
                  ? record['lastestTransaction']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
          ]
        : [
            {
              title: 'UserID',
              dataIndex: 'userId',
              key: 'dashboard-insight-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.userId
                  ? record.userId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Line User Id',
              dataIndex: 'lineUserId',
              key: 'dashboard-insight-line-user-id-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.lineUserId
                  ? record.lineUserId
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Verify Code',
              dataIndex: 'verifyCode',
              key: 'dashboard-insight-verify-code-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.verifyCode
                  ? record.verifyCode
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Full Name',
              dataIndex: 'name',
              key: 'dashboard-insight-user-info-name',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.name
                  ? record.name
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Gender',
              dataIndex: 'gender',
              key: 'dashboard-insight-user-info-sex',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'sex',
              },
              onFilter: (value, record) =>
                record.gender
                  ? record.gender
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Mobile Number',
              dataIndex: 'mobileNumber',
              key: 'dashboard-insight-user-info-mobile-number',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.mobileNumber
                  ? record.mobileNumber
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Status',
              dataIndex: 'InactiveStatus',
              key: 'imp-usr-dtl-status',
              filters: [],
              onFilter: (value, record) =>
                record.InactiveStatus.indexOf(value) === 0,
            },
            {
              title: 'Reference Info',
              dataIndex: 'referenceInfo',
              key: 'dashboard-insight-reference-info-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo
                  ? record.referenceInfo
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Reference Info2',
              dataIndex: 'referenceInfo2',
              key: 'dashboard-insight-reference-info-2-header',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.referenceInfo2
                  ? record.referenceInfo2
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Date of Birth',
              dataIndex: 'birthday',
              key: 'dashboard-insight-date-of-birth',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.birthday
                  ? record.birthday
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Age',
              dataIndex: 'age',
              key: 'dashboard-insight-age',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.age
                  ? record.age
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Register On',
              dataIndex: 'registeredOn',
              key: 'dashboard-insight-user-info-register',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.registeredOn
                  ? record.registeredOn
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'dashboard-insight-user-info-email',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record.email
                  ? record.email
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account Shopee',
              dataIndex: 'accountShopee',
              key: 'dashboard-insight-user-info-account-shopee',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountShopee']
                  ? record['accountShopee']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account LAZADA',
              dataIndex: 'accountLazada',
              key: 'dashboard-insight-user-info-account-lazada',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountLazada']
                  ? record['accountLazada']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Account Tiktok',
              dataIndex: 'accountTikTok',
              key: 'dashboard-insight-user-info-account-tiktok',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_connect',
              },
              onFilter: (value, record) =>
                record['accountTikTok']
                  ? record['accountTikTok']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Freq / M',
              dataIndex: 'freqLogin',
              key: 'dashboard-insight-user-info-frequency',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqLogin']
                  ? record['freqLogin']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Frequency',
              dataIndex: 'freqloginprevious',
              key: 'dashboard-insight-user-info-frequency-previous',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['freqloginprevious']
                  ? record['freqloginprevious']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Earn Point / Stamp',
              dataIndex: 'pointEarn',
              key: 'dashboard-insight-user-info-total-earn-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointEarn']
                  ? record['pointEarn']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },

            {
              title: 'Point / Stamp On Hand',
              dataIndex: 'pointOnHand',
              key: 'dashboard-insight-user-info-point-stamp',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record['pointOnHand']
                  ? record['pointOnHand']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Point Expired on',
              dataIndex: 'pointExpireLastQuarter',
              key: 'dashboard-insight-point-expired-on',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_number',
              },
              onFilter: (value, record) =>
                record.pointExpireLastQuarter
                  ? record.pointExpireLastQuarter
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Total Spending',
              dataIndex: 'totalSpending',
              key: 'dashboard-insight-user-info-total-spending',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata_digi',
              },
              onFilter: (value, record) =>
                record['totalSpending']
                  ? record['totalSpending']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
            {
              title: 'Lastest Transaction',
              dataIndex: 'lastestTransaction',
              key: 'dashboard-insight-user-info-lastest-transaction',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'isdata',
              },
              onFilter: (value, record) =>
                record['lastestTransaction']
                  ? record['lastestTransaction']
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : '',
              onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
            },
          ]
    }
    this.init()
  },
  mounted() {
    this.getCRMPlusProfile()
    this.currentLocale = Locale.getLocaleShort()
  },
  watch: {
    show(newVal, oldVal) {
      console.log('Prop show: ', newVal, ' | was: ', oldVal)
      if (newVal == true) {
        this.getListInsight(0).then(() => {
          if (Vue.bzbsConfig.businessRule.webFor.PH) {
            this.columnsInsight = _.forEach(
              this.columnsInsight,
              (column, index) => {
                if (column) {
                  if (column.dataIndex) {
                    if (column.dataIndex == 'lineMarketing') {
                      this.columnsInsight.splice(index, 1)
                    }
                  }
                }
              },
            )
            this.setLocale()
          } else {
            this.setLocale()
          }
          this.ModalShow(newVal)
        })
      }
    },
  },
  methods: {
    setLocale() {
      console.log('columnsInsight : ', this.columnsInsight)
      _.forEach(this.columnsInsight, column => {
        var customTitle = null
        if (
          this.lbl[column.key] ==
          this.lbl['dashboard-insight-reference-info-header']
        ) {
          var titleRef1 = this.referenceCustomName
            ? this.referenceCustomName
            : this.lbl['manage-usr-reference-number']
          customTitle = titleRef1
        }
        if (
          this.lbl[column.key] ==
          this.lbl['dashboard-insight-reference-info-2-header']
        ) {
          var titleRef2 = this.referenceCustomName2
            ? this.referenceCustomName2
            : this.lbl['manage-usr-reference-number2']
          customTitle = titleRef2
        }
        if (column.key == 'dashboard-insight-point-expired-on') {
          column.title = this.lbl[column.key] + ' ' + this.lastQuarter
        } else {
          column.title = customTitle ? customTitle : this.lbl[column.key]
        }
      })
    },
    init() {
      this.setFilter()
    },
    async setFilter() {
      const list = InfiniteHelper.statusList()

      const statusList = _.map(list, e => {
        return { value: e.name, text: e.name }
      })

      this.$set(this.columnsInsight[5], 'filters', statusList)
    },
    ModalShow(newVal) {
      this.showModalInsight = newVal
    },
    checkQuarter(enddate) {
      let toDate = moment(enddate).toDate()
      let newDate = new Date()
      if (
        toDate >= new Date(newDate.getFullYear(), 0, 1) &&
        toDate <= new Date(newDate.getFullYear(), 2, 31)
      ) {
        return moment(new Date(newDate.getFullYear(), 2, 31)).format('D MMM')
      } else if (
        toDate >= new Date(newDate.getFullYear(), 3, 1) &&
        toDate <= new Date(newDate.getFullYear(), 5, 30)
      ) {
        return moment(new Date(newDate.getFullYear(), 5, 30)).format('D MMM')
      } else if (
        toDate >= new Date(newDate.getFullYear(), 6, 1) &&
        toDate <= new Date(newDate.getFullYear(), 8, 30)
      ) {
        return moment(new Date(newDate.getFullYear(), 8, 30)).format('D MMM')
      } else if (
        toDate >= new Date(newDate.getFullYear(), 9, 1) &&
        toDate <= new Date(newDate.getFullYear(), 11, 31)
      ) {
        return moment(new Date(newDate.getFullYear(), 11, 31)).format('D MMM')
      }
    },
    dateFormatGetLastUpdate(date) {
      if (this.currentLocale == 'th') {
        moment.locale('th')
      } else {
        moment.locale('en')
      }
      let toDate = moment(date).toDate()
      let setDate = toDate.setDate(toDate.getDate() - 1)
      let settoDate = moment(setDate).toDate()
      var strDate = moment(settoDate).format('D MMMM ')
      var year = moment(settoDate).format('YYYY')

      return strDate + year
    },
    getListInsight() {
      this.dataInsight = null
      this.totalInsight = 0
      this.currentInsight = 0
      this.handleLoading(true)
      this.lastUpdate = this.dateFormatGetLastUpdate(this.data.enddate)
      this.lastQuarter = this.checkQuarter(this.data.enddate)
      let params = {
        point_option: 'all',
        startdate: this.data.startdate,
        enddate: this.data.enddate,
      }
      return new Promise(resolve => {
        BzbsDashboard.apiGetUserInfo(params)
          .then(res => {
            var userinfo = []

            console.log('res.data.data : ', res.data.data)

            if (this.isCompany == false) {
              userinfo = _.filter(res.data.data, item => {
                if (
                  item.birthday != '-' &&
                  item.birthday != '' &&
                  item.birthday != null
                )
                  return item
              })
            } else {
              userinfo = _.filter(res.data.data, item => {
                if (
                  item.gender != '-' &&
                  item.gender != '' &&
                  item.gender != null
                )
                  return item
              })
            }

            if (userinfo.length > 0) {
              if (this.currentInsight == 0) {
                this.currentInsight = 1
              }
            } else {
              this.currentInsight = 0
            }

            this.genListInsight(userinfo)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetUserInfo error', error)
            resolve(error)
          })
      })
    },
    genListInsight(userinfo) {
      this.dataInsight = userinfo
      this.totalInsight = userinfo.length
      this.handleLoading(false)
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.setReferenceCustom(res.data)
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    setReferenceCustom(data) {
      if (
        data.ReferenceCustomName === '' ||
        data.ReferenceCustomName === null
      ) {
        this.referenceCustomName = this.lbl['manage-usr-reference-number']
      }
      if (
        data.ReferenceCustomName2 === '' ||
        data.ReferenceCustomName2 === null
      ) {
        this.referenceCustomName2 = this.lbl['manage-usr-reference-number2']
      }
      if (data.ReferenceCustomName) {
        if (this.currentLocale == 'th') {
          this.referenceCustomName = JSON.parse(data.ReferenceCustomName).NameTH
          this.referenceCustomName2 = JSON.parse(
            data.ReferenceCustomName2,
          ).NameTH
        } else {
          this.referenceCustomName = JSON.parse(data.ReferenceCustomName).NameEN
          this.referenceCustomName2 = JSON.parse(
            data.ReferenceCustomName2,
          ).NameEN
        }
      }
    },
    onChangePage(page) {
      this.currentInsight = page
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleTableReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    onChangeTable(pagination, filters, sorter) {
      var scrollDiv = document.getElementById('campaign-list-table').offsetTop
      window.scrollTo({ top: scrollDiv, behavior: 'smooth' })
      var params = {
        filters: filters,
        order_by_property: sorter.field,
        is_order_by_asc: sorter.order == 'ascend' ? true : false,
      }
      console.log('params table', params)
      this.$emit('paramsExport', params)
    },
    exportExcel() {
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_insights_download',
        'on click',
      )
      var data = _.map(this.dataInsight, item => {
        let sex = ''
        if (item.gender == 'male') {
          sex = this.lbl['Male']
        } else if (item.gender == 'female') {
          sex = this.lbl['Female']
        } else {
          sex = this.lbl['Other']
        }

        let freqm = ''
        if (item['freqLogin'] != null) {
          freqm = item['freqLogin']
        } else {
          freqm = '0'
        }

        let freqprevious = ''
        if (item['freqloginprevious'] != null) {
          freqprevious = item['freqloginprevious']
        } else {
          freqprevious = '0'
        }

        let freqq = ''
        if (item['pointEarn'] != null) {
          freqq = item['pointEarn']
        } else {
          freqq = '0'
        }

        let point_stamp = ''
        if (item['pointOnHand'] != null) {
          point_stamp = item['pointOnHand']
        } else {
          point_stamp = '0'
        }

        let totalspending = ''
        if (item['totalSpending'] != null) {
          totalspending = item['totalSpending']
        } else {
          totalspending = '0.00'
        }

        let dob = ''
        if (
          item.birthday != null &&
          item.birthday != '-' &&
          item.birthday != ''
        ) {
          dob = new Date(item.birthday)
        } else {
          dob = '-'
        }

        let regisdate = ''
        if (
          item.registeredOn != null &&
          item.registeredOn != '-' &&
          item.registeredOn != ''
        ) {
          regisdate = new Date(item.registeredOn)
        } else {
          regisdate = '-'
        }

        let lastest_transaction = ''
        if (
          item['lastestTransaction'] != null &&
          item['lastestTransaction'] != '-' &&
          item['lastestTransaction'] != ''
        ) {
          lastest_transaction = new Date(item['lastestTransaction'])
        } else {
          lastest_transaction = '-'
        }

        let point_expire = ''
        if (item.pointExpireLastQuarter != null) {
          point_expire = item.pointExpireLastQuarter
        } else {
          point_expire = '0'
        }

        var line_user_id = this.lbl['dashboard-insight-line-user-id-header']
        var user_id = this.lbl['dashboard-insight-user-id-header']
        var verify_code = this.lbl['dashboard-insight-verify-code-header']
        var full_name = this.lbl['dashboard-insight-user-info-name']
        var gender = this.lbl['dashboard-insight-user-info-sex']
        var mobile_number =
          this.lbl['dashboard-insight-user-info-mobile-number']
        var status = this.lbl['imp-usr-dtl-status']
        var reference_info = this.lbl['dashboard-insight-reference-info-header']
        var reference_info2 =
          this.lbl['dashboard-insight-reference-info-2-header']
        var member_level = this.lbl['dashboard-insight-member-level']
        var date_of_birth = this.lbl['dashboard-insight-date-of-birth']
        var age = this.lbl['dashboard-insight-age']
        var register = this.lbl['dashboard-insight-user-info-register']
        var email = this.lbl['dashboard-insight-user-info-email']
        var freq_m = this.lbl['dashboard-insight-user-info-frequency']
        var freq_previous =
          this.lbl['dashboard-insight-user-info-frequency-previous']
        var earn = this.lbl['dashboard-insight-user-info-total-earn-stamp']
        var point = this.lbl['dashboard-insight-user-info-point-stamp']
        var point_expired =
          this.lbl['dashboard-insight-point-expired-on'] +
          ' ' +
          this.lastQuarter
        var total_spending =
          this.lbl['dashboard-insight-user-info-total-spending']
        var transaction =
          this.lbl['dashboard-insight-user-info-lastest-transaction']
        var termAndCondition =
          this.lbl['dashboard-insight-user-info-term-and-condition']
        var dataPrivacy = this.lbl['dashboard-insight-user-info-privacy-policy']
        var lineMarketing =
          this.lbl['dashboard-insight-user-info-line-marketing']
        var smsMarketing = this.lbl['dashboard-insight-user-info-sms-marketing']
        var emailMarketing =
          this.lbl['dashboard-insight-user-info-email-marketing']
        var accountShopee =
          this.lbl['dashboard-insight-user-info-account-shopee']
        var accountLazada =
          this.lbl['dashboard-insight-user-info-account-lazada']
        var accountTikTok =
          this.lbl['dashboard-insight-user-info-account-tiktok']

        if (this.isBeeBanefit) {
          return {
            [line_user_id]: item.lineUserId ? item.lineUserId : '-',
            [user_id]: item.userId ? item.userId : '-',
            [verify_code]: item.verifyCode ? item.verifyCode : '-',
            [full_name]: item.name ? item.name : '-',
            [gender]: sex ? sex : '-',
            [mobile_number]: item.mobileNumber ? item.mobileNumber : '-',
            [status]: item.InactiveStatus ? item.InactiveStatus : '-',
            [reference_info]: item.referenceInfo ? item.referenceInfo : '-',
            [reference_info2]: item.referenceInfo2 ? item.referenceInfo2 : '-',
            [date_of_birth]: dob ? dob : '-',
            [age]: item.age ? item.age : '-',
            [register]: regisdate ? regisdate : '-',
            [email]: item.email ? item.email : '-',
            [accountShopee]: item.accountShopee
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [accountLazada]: item.accountLazada
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [accountTikTok]: item.accountTikTok
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [freq_m]: { t: 'n', v: freqm, z: '0' },
            [freq_previous]: { t: 'n', v: freqprevious, z: '0' },
            [earn]: { t: 'n', v: freqq, z: '0' },
            [point]: { t: 'n', v: point_stamp, z: '0' },
            [point_expired]: { t: 'n', v: point_expire, z: '0' },
            [total_spending + ' (฿)']: {
              t: 'n',
              v: totalspending,
              z: '#,##0.00',
            },
            [transaction]: lastest_transaction ? lastest_transaction : '-',
          }
        } else {
          return {
            [line_user_id]: item.lineUserId ? item.lineUserId : '-',
            [user_id]: item.userId ? item.userId : '-',
            [verify_code]: item.verifyCode ? item.verifyCode : '-',
            [full_name]: item.name ? item.name : '-',
            [gender]: sex ? sex : '-',
            [mobile_number]: item.mobileNumber ? item.mobileNumber : '-',
            [status]: item.InactiveStatus ? item.InactiveStatus : '-',
            [reference_info]: item.referenceInfo ? item.referenceInfo : '-',
            [reference_info2]: item.referenceInfo2 ? item.referenceInfo2 : '-',
            [member_level]: item.memberLevel ? item.memberLevel : '-',
            [date_of_birth]: dob ? dob : '-',
            [age]: item.age ? item.age : '-',
            [register]: regisdate ? regisdate : '-',
            [email]: item.email ? item.email : '-',
            [accountShopee]: item.accountShopee
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [accountLazada]: item.accountLazada
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [accountTikTok]: item.accountTikTok
              ? this.lbl['dashboard-insight-user-connect']
              : this.lbl['dashboard-insight-user-unconnect'],
            [freq_m]: { t: 'n', v: freqm, z: '0' },
            [freq_previous]: { t: 'n', v: freqprevious, z: '0' },
            [earn]: { t: 'n', v: freqq, z: '0' },
            [point]: { t: 'n', v: point_stamp, z: '0' },
            [point_expired]: { t: 'n', v: point_expire, z: '0' },
            [total_spending + ' (฿)']: {
              t: 'n',
              v: totalspending,
              z: '#,##0.00',
            },
            [transaction]: lastest_transaction ? lastest_transaction : '-',
            [termAndCondition]: item.termAndCondition
              ? item.termAndCondition
              : this.lbl['unaccepted-consent'],
            [dataPrivacy]: item.dataPrivacy
              ? item.dataPrivacy
              : this.lbl['unaccepted-consent'],
            [lineMarketing]: item.lineMarketing
              ? item.lineMarketing
              : this.lbl['unaccepted-consent'],
            [smsMarketing]: item.smsMarketing
              ? item.smsMarketing
              : this.lbl['unaccepted-consent'],
            [emailMarketing]: item.emailMarketing
              ? item.emailMarketing
              : this.lbl['unaccepted-consent'],
          }
        }
      })

      if (data.length <= 10000) {
        var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
        var wscols = [
          { wch: 40 },
          { wch: 40 },
          { wch: 12 },
          { wch: 20 },
          { wch: 8 },
          { wch: 14 },
          { wch: 12 },
          { wch: 30 },
          { wch: 14 },
          { wch: 14 },
          { wch: 7 },
          { wch: 14 },
          { wch: 14 },
          { wch: 14 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 30 },
          { wch: 20 },
          { wch: 20 },
        ]
        dataWS['!cols'] = wscols
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'CRMPlus_' + moment(new Date()).format('YYYY-MM-DD') + '.xlsx',
        )
      } else {
        for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          var ws_cols = [
            { wch: 40 },
            { wch: 40 },
            { wch: 12 },
            { wch: 20 },
            { wch: 8 },
            { wch: 14 },
            { wch: 12 },
            { wch: 30 },
            { wch: 14 },
            { wch: 14 },
            { wch: 7 },
            { wch: 14 },
            { wch: 14 },
            { wch: 14 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
          ]
          data_WS['!cols'] = ws_cols
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data_WS)
          XLSX.writeFile(
            wb,
            'CRMPlus_' +
              (i + 1) +
              '_' +
              moment(new Date()).format('YYYY-MM-DD') +
              '.xlsx',
          )
        }
      }

      this.handleLoading(false)
    },
    openHistory(record) {
      return {
        on: {
          click: () => {
            this.dataInsightsHistory = {
              userId: record.userId,
              startdate: this.data.startdate,
              enddate: this.data.enddate,
            }
            this.$nextTick(() => {
              this.showModal = true
            })
          },
        },
      }
    },
    InsightsClose() {
      this.showModalInsight = false
      Account.bzbsAnalyticTracking(
        'home_page',
        'dashboard_details',
        'click_insights_close',
        'on click',
      )
      this.$emit('changeInsights', false)
    },
    ChangeInsightsHistory(res) {
      this.showModal = res
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../../style/base/common.scss';

.modal-Insights {
  .last-update {
    color: $color-red-52;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;

    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .modal-header {
    padding: 0rem 1rem 1rem 1rem !important;
    border-bottom: 1px solid #dee2e6;
  }

  .div-import {
    float: right;
    margin-right: 4px;
  }

  .ico-excel {
    background-color: #217346;
    height: 1.5vw;
    padding: 0.25vw;
  }

  .button-import {
    height: 45px;
    padding: 5px;
    width: 130px;
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #000;
  }

  .button-import:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .d-box-search {
    display: -webkit-box;
    padding: 20px 10px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .d-box-search {
      display: initial;
      padding: 0px;
      color: rgba(0, 0, 0, 0.85);
    }

    .div-import {
      float: left;
      margin-right: 4px;
    }

    .ico-excel {
      background-color: #217346;
      height: 8.5vw;
      padding: 1.25vw;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 568px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .d-box-search {
      display: initial;
      padding: 0px;
      color: rgba(0, 0, 0, 0.85);
    }

    .div-import {
      float: left;
      margin-right: 4px;
    }

    .ico-excel {
      background-color: #217346;
      height: 4.5vw;
      padding: 0.5vw;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      background-color: #217346;
      height: 4.5vw;
      padding: 0.5vw;
    }
  }

  //แนวนอน mobile (iPhone x)
  @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ant-modal {
      width: 90% !important;
    }

    .ico-excel {
      background-color: #217346;
      height: 4vw;
      padding: 0.6vw;
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1023px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      background-color: #217346;
      height: 4vw;
      padding: 0.6vw;
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ico-excel {
      background-color: #217346;
      height: 3.2vw;
      padding: 0.4vw;
    }
  }

  //แนวตั้ง iPad Pro
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: portrait) {
    .ico-excel {
      background-color: #217346;
      height: 3.2vw;
      padding: 0.6vw;
    }
  }

  //แนวนอน iPad Pro
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: landscape) {
    .ico-excel {
      background-color: #217346;
      height: 2.5vw;
      padding: 0.4vw;
    }
  }
}
.modal-Insights .ant-pagination-next .ant-pagination-item-link,
.modal-Insights .ant-pagination-prev .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}
</style>
